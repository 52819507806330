import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const LearnMoreContentMobile = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="pricing-content-mobile">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "24px",
          marginBottom: "10px",
          textAlign: "center",
          paddingTop: "15px",
          width: "60%",
        }}
      >
        {translate("rimbaudIntroduction")}
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "0px",
          paddingTop: "10px",
          textAlign: "justify",
        }}
      >
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {translate("rimbaudRevolution")}
        </p>

        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "10px",
            color: "white",
          }}
        >
          {translate("howItWorks")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <strong style={{ color: "white" }}>
            {translate("chooseYourStyle")}
          </strong>{" "}
          {translate("chooseYourStyleDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <strong style={{ color: "white" }}>
            {translate("letAIHandleRest")}
          </strong>{" "}
          {translate("letAIHandleRestDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <strong style={{ color: "white" }}>{translate("easyToUse")}</strong>{" "}
          {translate("easyToUseDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "15px",
            color: "white",
          }}
        >
          {translate("whyChooseRimbaud")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <strong style={{ color: "white" }}>{translate("simplicity")}</strong>{" "}
          {translate("simplicityDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <strong style={{ color: "white" }}>
            {translate("personalization")}
          </strong>{" "}
          {translate("personalizationDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <strong style={{ color: "white" }}>{translate("privacy")}</strong>{" "}
          {translate("privacyDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          {translate("joinRimbaudRevolution")}
        </p>
      </div>
    </div>
  );
};

export default LearnMoreContentMobile;
