import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const InputArea = ({ value, onChange }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="input-area">
      <input
        type="email"
        placeholder={translate("emailAddress")}
        value={value} // Utiliser la valeur de l'état du composant parent
        onChange={(e) => onChange(e.target.value)} // Informer le parent des changements
      />
    </div>
  );
};

export default InputArea;
