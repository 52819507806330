import React, { useContext, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import { LanguageContext } from "../../context/LanguageContext";
import { useMediaQuery } from "react-responsive";

const CookieConsent = () => {
  const { translate } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);  
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          position: "fixed",
          bottom: 20,
          right: isMobile ? "auto" : 40,
          left: isMobile ? "auto" : "auto",
          backgroundColor: "rgba(28, 40, 59, 0.8)",
          color: "white",
          padding: "10px",
          width: "calc(100% - 40px)", 
          minWidth: "300px", // Minimum width
          maxWidth: "300px",
          margin: isMobile ? "0 auto" : "0",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogContent>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            paddingBottom: "10px",
          }}
        >
          {translate("This website uses cookies.")}
        </div>
        <div>
          {translate(
            "We use cookies to analyze website traffic and optimize your website experience. By accepting our use of cookies, your data will be aggregated with all other user data."
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAccept}
          style={{ color: "white", borderColor: "white" }}
        >
          {translate("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsent;
