// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

// const app = initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
// });

// export const auth = getAuth(app);
// export default app;
// export const db = getFirestore(app);
// export const storage = getStorage(app);
// export const analytics = getAnalytics(app);

// Importez les fonctions nécessaires depuis les SDK Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics, isSupported } from "firebase/analytics";

// Configuration de votre application Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

// Initialisation de l'application Firebase
const app = initializeApp(firebaseConfig);

// Initialisation des services Firebase que vous utilisez
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialisation conditionnelle de Firebase Analytics
let analyticsInstance = null;
isSupported()
  .then((isSupported) => {
    if (isSupported) {
      analyticsInstance = getAnalytics(app);
      console.log("Firebase Analytics is supported and has been initialized.");
    } else {
      console.log("Firebase Analytics is not supported in this environment.");
    }
  })
  .catch((error) => {
    console.error(
      "An error occurred while initializing Firebase Analytics:",
      error
    );
  });

export const analytics = () => analyticsInstance;
