import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { usePremiumStatus } from "../../hooks/usePremiumStatus";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Tooltip from "@mui/material/Tooltip";

const ProfileMenu = ({ onClick, profilPic }) => {
  const { translate } = useContext(LanguageContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();
  const buttonRef = useRef();
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const [isPremium] = usePremiumStatus();

  const onClickSetting = () => {
    navigate("/settings");
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="profile-menu-container">
      <div
        className="profile-icon-wrapper"
        ref={buttonRef}
        onClick={toggleModal}
      >
        {" "}
        <img src={profilPic} alt="Profile" className="profile-icon" />
        {
          isPremium && (
            <Tooltip title={translate("Premium")} placement="right">
              <WorkspacePremiumIcon
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  color: "gold", // Utiliser 'gold' pour la couleur dorée
                  transform: "translate(-280%, 50%)", // Ajuster si nécessaire pour positionner l'icône
                }}
              />
            </Tooltip>
          )

          // <span className="premium-icon">&#9733;</span>
        }
        <span className={`profile-arrow ${isModalOpen ? "open" : ""}`}>
          &#9660;
        </span>
      </div>

      {isModalOpen && (
        <div className="profile-modal" ref={modalRef}>
          <ul className="profile-modal-options">
            <li style={{ textAlign: "center" }} onClick={onClickSetting}>
              {translate("profile")}
            </li>
            <li style={{ textAlign: "center" }} onClick={onClick}>
              {translate("signOut")}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
