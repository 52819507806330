import React from "react";
import language from "../../api/language";

const StyledButtonEmptyWithArrow = ({
  selectedLanguageCode,
  onSelectLanguage,
}) => {
  const handleChange = (event) => {
    const newLanguageCode = event.target.value;
    const selectedOption = language.find(
      (option) => option.code === newLanguageCode
    );
    if (selectedOption) {
      onSelectLanguage(selectedOption.textDropDownEn);
    }
  };

  return (
    <select
      className="styledButtonDropdown"
      onChange={handleChange}
      value={selectedLanguageCode}
    >
      {language.map((option) => (
        <option key={option.id} value={option.code}>
          {option.textDropDownEn}
        </option>
      ))}
    </select>
  );
};

export default StyledButtonEmptyWithArrow;
