// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth } from "../context/AuthContext";

// function ProtectedRoute({ children }) {
//   const { currentUser } = useAuth();
//   if (!currentUser) {
//     return <Navigate to="/homepage" />;
//   }
//   return children;
// }

// export default ProtectedRoute;

import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { CircularProgress } from "@mui/material";

function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simuler une vérification d'authentification
    const checkAuth = () => {
      setLoading(false);
    };

    checkAuth();
  }, [currentUser]);

  if (loading) {
    return <CircularProgress />; // Ou tout autre indicateur de chargement que vous préférez
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default ProtectedRoute;
