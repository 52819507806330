import React, { useContext, useEffect, useState } from "react";
import PricingBox from "../components/misc/PricingBox";
import { LanguageContext } from "../context/LanguageContext";
import { useAuth } from "../context/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { usePremiumStatus } from "../hooks/usePremiumStatus";
import { usePortalUrl } from "../hooks/usePortalUrl";
import { useCheckoutUrl } from "../hooks/useCheckoutUrl";

const PricingContent = () => {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [, setIsSelectedPlanBasic] = useState(false);
  // const [currency, setCurrency] = useState("USD");
  // const [localPrice, setLocalPrice] = useState("5");
  const currency = "USD";
  const localPrice = "5";
  const [isPremium] = usePremiumStatus();
  const { url: portalUrl, loading, error } = usePortalUrl();
  const priceId = process.env.REACT_APP_STRIPE_PRODUCT_KEY;
  const [checkoutUrl, checkoutError] = useCheckoutUrl(priceId);

  const handlePortalRedirect = () => {
    if (portalUrl) {
      window.location.href = portalUrl;
    } else {
      console.error("Portal URL not available");
      alert("An error occurred while trying to access the Stripe portal.");
    }
  };

  console.log("checkOutUrl: ", checkoutUrl);
  console.log("priceId:", priceId);

  const handleCheckoutClick = () => {
    if (checkoutError) {
      console.error(checkoutError);
      alert(
        "Une erreur est survenue lors de la tentative de paiement. Veuillez réessayer."
      );
    } else if (checkoutUrl) {
      window.location.href = checkoutUrl; // Rediriger l'utilisateur vers l'URL de paiement Stripe
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentUser) {
      const docRef = doc(db, "roles", currentUser.uid);

      unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            setIsSelectedPlanBasic(docSnap.data().role === "Free plan");
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error fetching role: ", error);
        }
      );
    }

    return () => unsubscribe(); // Cleanup the subscription on component unmount
  }, [currentUser]);

  return (
    <div className="generate-content">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "50px",
        }}
      >
        <div
          className="main-title-homepage"
          style={{
            fontSize: "40px",
          }}
        >
          {translate("pricing")}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "40%",
            paddingTop: "40px",
          }}
        >
          <PricingBox
            link="#"
            planName={translate("freePlan")}
            planDetail={translate("getStartedDescription")}
            price="0"
            detailTitle1={translate("monthlyMailsLimit")}
            detail1={translate("craftEmailsDescription")}
            detailTitle2={translate("basicWritingAssistance")}
            detail2={translate("writingAssistanceDescription")}
            detailTitle3={translate("emailCustomization")}
            detail3={translate("basicTemplatesDescription")}
            isSelectedPlan={!isPremium}
            handleAction={isPremium ? handlePortalRedirect : undefined}
            isFreePlan={true}
            loading={loading}
            portalUrl={portalUrl}
            error={error}
          />
          {/* <PricingBox
            link="#"
            planName={translate("premiumPlan")}
            planDetail={translate("elevateEmailGameDescription")}
            price={`${localPrice} ${currency}`} // Afficher le prix dans la devise locale
            detailTitle1={translate("unlimitedEmailGeneration")}
            detail1={translate("sendEmailsDescription")}
            detailTitle2={translate("archiveConversations")}
            detail2={translate("archiveConversationsDetail")}
            detailTitle3={translate("oneClickGmailContent")}
            detail3={translate("oneClickGmailContentDetail")}
            isSelectedPlan={isPremium}
            handleAction={!isPremium ? handleCheckoutClick : undefined}
            isFreePlan={false}
          /> */}
          <PricingBox
            link="#"
            planName={translate("premiumPlan")}
            planDetail={translate("elevateEmailGameDescription")}
            price="5"
            detailTitle1={translate("unlimitedEmailGeneration")}
            detail1={translate("sendEmailsDescription")}
            detailTitle2={translate("archiveConversations")}
            detail2={translate("archiveConversationsDetail")}
            detailTitle3={translate("oneClickGmailContent")}
            detail3={translate("oneClickGmailContentDetail")}
            isSelectedPlan={isPremium}
            handleAction={!isPremium ? handleCheckoutClick : undefined}
            isFreePlan={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingContent;
