import React from "react";

const StyledButton = ({ onClick, children }) => {
  return (
    <button className="styledButton" onClick={onClick}>
      {children}
    </button>
  );
};

export default StyledButton;
