import React from "react";

const StyledButtonMobile = ({ onClick, children }) => {
  return (
    <button className="styledButtonMobile" onClick={onClick}>
      {children}
    </button>
  );
};

export default StyledButtonMobile;
