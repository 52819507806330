import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const EmailSizeMobile = ({ countWord, setCountWord }) => {
  const { translate } = useContext(LanguageContext);
  const incrementWordCount = () => {
    setCountWord((prevCount) => prevCount + 5);
  };

  const decrementWordCount = () => {
    if (countWord > 0) {
      setCountWord((prevCount) => prevCount - 5);
    }
  };

  return (
    <div className="email-size-adjuster-mobile">
      <button onClick={decrementWordCount}>-</button>
      <span>{translate("emailSize")}</span>
      <button onClick={incrementWordCount}>+</button>
      <span>
        ({countWord} {translate("words")})
      </span>
    </div>
  );
};

export default EmailSizeMobile;
