import React from "react";
import NavbarMain from "./NavbarMain";
import Footer from "./Footer";
import LibraryContent from "./LibraryContent";
import { useMediaQuery } from "react-responsive";
import LibraryContentMobile from "./LibraryContentMobile";
import NavbarMainMobile from "./NavbarMainMobile";

const Library = () => {
  const activeCategory = "Library";
  const activeCategoryMobile = "library";

  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div>
      {isMobile ? (
        <>
          <NavbarMainMobile activeCategoryMobile={activeCategoryMobile} />
          <LibraryContentMobile />
        </>
      ) : (
        <>
          <NavbarMain activeCategory={activeCategory} />
          <LibraryContent />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Library;
