import { toast } from "react-toastify";

export const showCustomToast = (message, type, duration = 3000) => {
  const toastOptions = {
    position: "top-center",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,

    progress: undefined,
    theme: "dark",
    style: {
      minWidth: "500px",
    },
  };

  if (type === "success") {
    toast.success(message, toastOptions);
  } else if (type === "error") {
    toast.error(message, toastOptions);
  } else {
    toast.info(message, toastOptions);
  }
};
