import React, { useContext } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { LanguageContext } from "../../context/LanguageContext";
import poweredByStripe from "../../images/poweredbystripebis.png";
// import { useCheckoutUrl } from "../../hooks/useCheckoutUrl";

const PricingBox = ({
  planName,
  planDetail,
  price,
  detailTitle1,
  detail1,
  detailTitle2,
  detail2,
  detailTitle3,
  detail3,
  isSelectedPlan,
  isFreePlan,
  portalUrl,
  loading,
  error,
  handleAction,
}) => {
  const { translate } = useContext(LanguageContext);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          width: "270px",
          height: "520px",
          border: "1px solid #ab73ee",
          borderRadius: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "15%",
            borderBottom: "1px solid #54565A",
          }}
        >
          <div
            style={{ fontSize: "16px", paddingBottom: "10px", color: "white" }}
          >
            {planName}
          </div>
          <div className="roboto-font" style={{ fontSize: "12px" }}>
            {planDetail}
          </div>
        </div>
        <div
          className="roboto-font"
          style={{
            width: "100%",
            height: "35%",
            fontSize: "47px",
            fontWeight: "100",
            paddingTop: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #54565A",
          }}
        >
          ${price}
          <span
            style={{
              fontSize: "29px",
              fontWeight: "100",
              paddingBottom: "20px",
            }}
          >
            /mo
          </span>
          {isFreePlan ? (
            <>
              {loading ? (
                <div className="normalTextWhite">Loading...</div>
              ) : (
                <div
                  className="kamerik-font button-pricing"
                  style={{
                    backgroundColor: isSelectedPlan ? "#ab73ee" : "#d0d6de",
                    color: isSelectedPlan ? "white" : "#6e7279",
                    cursor: portalUrl ? "pointer" : "default",
                  }}
                  onClick={portalUrl ? handleAction : undefined}
                >
                  {isSelectedPlan
                    ? translate("selectedPlan")
                    : translate("chooseThisPlan")}
                </div>
              )}
            </>
          ) : (
            <div
              className="kamerik-font button-pricing"
              style={{
                backgroundColor: isSelectedPlan ? "#ab73ee" : "#d0d6de",
                color: isSelectedPlan ? "white" : "#6e7279",
                cursor: "pointer",
              }}
              onClick={handleAction}
            >
              {isSelectedPlan
                ? translate("selectedPlan")
                : translate("chooseThisPlan")}
            </div>
          )}
        </div>
        <div style={{ width: "100%", height: "50%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "3px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon />
              <div className="title-detail">{detailTitle1}</div>
            </div>
            <div className="content-detail">{detail1}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "3px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon />
              <div className="title-detail">{detailTitle2}</div>
            </div>
            <div className="content-detail">{detail2}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "3px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon />
              <div className="title-detail">{detailTitle3}</div>
            </div>
            <div className="content-detail roboto-font">{detail3}</div>
          </div>
          {!isFreePlan ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: "ponk",
                paddingTop: "10px",
              }}
            >
              <img src={poweredByStripe} className="stripe-logo" alt="" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingBox;
