import React, { useContext, useState } from "react";
import "../css/Navbar.css";
import StyledButton from "../components/buttons/StyledButton";
import StyledButtonEmpty from "../components/buttons/StyledButtonEmpty";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../components/misc/ProfileIcon";
import { useAuth } from "../context/AuthContext";
import { showCustomToast } from "../components/misc/CustomToast";
import { LanguageContext } from "../context/LanguageContext";
import LanguageIcon from "@mui/icons-material/Language";

const NavbarMain = ({ activeCategory }) => {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { currentUser, logOut } = useAuth();
  const navigateTo = (path) => () => {
    navigate(path);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  async function handleLogout() {
    setError("");
    try {
      await logOut();
      showCustomToast(
        `User ${currentUser.email} successfuly logged out`,
        "success"
      );

      navigate("/login");
    } catch {
      setError("Failed to logout");
      console.log("Error", error);
    }
  }

  // const [notifications, setNotifications] = useState([
  //   // {
  //   //   id: 1,
  //   //   message: "Découvrez les nouvelles fonctionnalités de notre produit !",
  //   //   link: "https://exemple.com/nouvelles-fonctionnalites",
  //   // },
  //   // {
  //   //   id: 2,
  //   //   message:
  //   //     "Votre abonnement arrive à expiration. Renouvelez-le dès maintenant.",
  //   //   link: "https://exemple.com/renouvellement",
  //   // },
  //   // {
  //   //   id: 3,
  //   //   message:
  //   //     "Mise à jour de sécurité importante disponible. Veuillez mettre à jour votre application.",
  //   //   link: "https://exemple.com/mise-a-jour-securite",
  //   // },
  //   // {
  //   //   id: 4,
  //   //   message: "Vous avez un nouveau message dans votre boîte de réception.",
  //   //   link: "https://exemple.com/boite-de-reception",
  //   // },
  //   // {
  //   //   id: 5,
  //   //   message:
  //   //     "Lisez notre dernier article de blog sur les tendances technologiques 2024.",
  //   //   link: "https://exemple.com/blog/tendances-technologiques-2024",
  //   // },
  // ]);

  return (
    <div className="navbar-container">
      <div className="navbar-left">
        <span className="app-name">Rimbaud</span>
        <span
          className={`navbar-item ${activeCategory === "Home" ? "active" : ""}`}
          onClick={navigateTo("/")}
        >
          {translate("home")}
        </span>

        <span
          className={`navbar-item ${
            activeCategory === "Learnmore" ? "active" : ""
          }`}
          onClick={navigateTo("/learnmore")}
        >
          {translate("aboutUs")}
        </span>

        {currentUser ? (
          <span
            className={`navbar-item ${
              activeCategory === "Generate" ? "active" : ""
            }`}
            onClick={navigateTo("/generate")}
          >
            {translate("generate")}
          </span>
        ) : (
          ""
        )}

        {currentUser ? (
          <span
            className={`navbar-item ${
              activeCategory === "Library" ? "active" : ""
            }`}
            onClick={navigateTo("/library")}
          >
            {translate("library")}
          </span>
        ) : (
          ""
        )}

        <span
          className={`navbar-item ${
            activeCategory === "Pricing" ? "active" : ""
          }`}
          onClick={navigateTo("/pricing")}
        >
          {translate("pricing")}
        </span>

        {currentUser ? (
          <span
            className={`navbar-item ${
              activeCategory === "Settings" ? "active" : ""
            }`}
            onClick={navigateTo("/settings")}
          >
            {translate("profile")}
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="navbar-right">
        <div style={{ marginRight: "10px" }}>
          <LanguageIcon
            style={{ marginRight: "10px", verticalAlign: "middle" }}
          />
          <select
            value={language}
            style={{
              marginRight: "5px",
              backgroundColor: "#181b20",
              color: "white",
              border: "1px solid #767474",
              borderRadius: "5px",
              padding: "5px 10px",
              outline: "none",
            }}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
          </select>
        </div>
        {currentUser ? (
          <>
            {/* <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              <NotificationsIcon
                notifications={notifications}
                setNotifications={setNotifications}
              />
            </div> */}
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              <ProfileIcon
                onClick={handleLogout}
                profilPic={currentUser?.photoURL}
                // notifications={notifications}
                // setNotifications={setNotifications}
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ paddingLeft: "0px", paddingRight: "10px" }}>
              <StyledButtonEmpty onClick={navigateTo("/login")}>
                Log in
              </StyledButtonEmpty>
            </div>
            <StyledButton onClick={navigateTo("/signup")}>Sign up</StyledButton>
          </>
        )}
      </div>
    </div>
  );
};

export default NavbarMain;
