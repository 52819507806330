import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SignUpButton from "../components/buttons/SignUpButton";
import { useAuth } from "../context/AuthContext";
import { LanguageContext } from "../context/LanguageContext";
import Lottie from "react-lottie";
import LottieAnim from "../components/misc/cIzwGT8Wfm.json";
import LanguageIcon from "@mui/icons-material/Language";

const LoginContent = () => {
  const navigate = useNavigate();
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const handleSignInClick = () => {
    navigate("/signup"); // Redirigez l'utilisateur vers /login
  };

  const { signInWithGoogle } = useAuth();

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  function handleSignInWithGoogle() {
    signInWithGoogle()
      .then((result) => {})
      .then(() => navigate("/"))
      .catch((error) => {});
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="signup-content">
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          className="main-title-signup"
          style={{
            fontSize: "40px",
            // marginTop: "200px"
          }}
        >
          {translate("welcomeBack")}
        </div>

        <div
          style={{ fontSize: "14px", paddingTop: "40px", fontWeight: "200px" }}
        >
          {translate("loginToContinueWriting")}
        </div>

        <div style={{ paddingTop: "30px" }}>
          <SignUpButton onClick={handleSignInWithGoogle}>
            {translate("logInWithGoogle")}
          </SignUpButton>
        </div>
        <div style={{ fontSize: "14px", paddingTop: "30px" }}>
          {translate("dontHaveAccount")}
          <span
            style={{
              cursor: "pointer",
              paddingLeft: "5px",
              color: "#ab73ee",
            }}
            onClick={handleSignInClick}
          >
            {translate("signUp")}
          </span>
        </div>

        <div style={{ marginTop: "25px" }}>
          <LanguageIcon
            style={{ marginRight: "10px", verticalAlign: "middle" }}
          />
          <select
            value={language}
            style={{
              marginRight: "5px",
              backgroundColor: "transparent",
              color: "black",
              border: "1px solid #767474",
              borderRadius: "5px",
              padding: "5px 10px",
              outline: "none",
            }}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
          </select>
        </div>
      </div>

      <div
        style={{
          width: "50%",
          backgroundColor: "#181b20",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="main-title-homepage"
          style={{
            fontSize: "40px",
            // marginTop: "200px"
            paddingBottom: "40px",
          }}
        >
          Rimbaud A.I.
        </div>

        <Lottie options={defaultOptions} height={100} width={150} />
        <div style={{ paddingTop: "40px" }}>
          {translate("transformEmailExperience")}
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="homepage-content">
  //     <div
  //       className="main-title-homepage"
  //       style={{
  //         fontSize: "40px",
  //         // marginTop: "200px"
  //       }}
  //     >
  //       {translate("logIn")}
  //     </div>

  //     <div style={{ paddingTop: "60px" }}>
  //       <SignUpButton onClick={handleSignInWithGoogle}>
  //         {translate("logInWithGoogle")}
  //       </SignUpButton>
  //     </div>
  //     <div style={{ fontSize: "14px", paddingTop: "15px" }}>
  //       {translate("dontHaveAccount")}
  //       <span
  //         style={{
  //           cursor: "pointer",
  //           paddingLeft: "5px",
  //           color: "#ab73ee",
  //         }}
  //         onClick={handleSignInClick}
  //       >
  //         {translate("signUp")}
  //       </span>
  //     </div>
  //   </div>
  // );
};

export default LoginContent;
