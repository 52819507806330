import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const LearnMoreContent = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="learnmore-content">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "40px",
          paddingLeft: "0px",
          paddingTop: "20px",
          width: "35%",
          textAlign: "center",
          // marginTop: "200px"
        }}
      >
        {translate("rimbaudIntroduction")}
      </div>

      {/* <h1 style={{ fontSize: "40px", paddingLeft: "80px", paddingTop: "20px" }}>
       
      </h1> */}
      <div style={{ width: "40%", paddingLeft: "0px", paddingTop: "30px" }}>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          {translate("rimbaudRevolution")}
        </p>
        <p
          style={{
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "15px",
            color: "white",
          }}
        >
          {translate("howItWorks")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          <strong style={{ color: "white" }}>
            {translate("chooseYourStyle")}
          </strong>{" "}
          {translate("chooseYourStyleDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          <strong style={{ color: "white" }}>
            {translate("letAIHandleRest")}
          </strong>{" "}
          {translate("letAIHandleRestDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          <strong style={{ color: "white" }}>{translate("easyToUse")}</strong>{" "}
          {translate("easyToUseDetail")}
        </p>
        <p
          style={{
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "15px",
            color: "white",
          }}
        >
          {translate("whyChooseRimbaud")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          <strong style={{ color: "white" }}>{translate("simplicity")}</strong>{" "}
          {translate("simplicityDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          <strong style={{ color: "white" }}>
            {translate("personalization")}
          </strong>{" "}
          {translate("personalizationDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          <strong style={{ color: "white" }}>{translate("privacy")}</strong>{" "}
          {translate("privacyDetail")}
        </p>
        <p
          className="roboto-font"
          style={{
            fontSize: "18px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          {translate("joinRimbaudRevolution")}
        </p>

        {/* <div
        className="main-title-homepage"
        style={{
          fontSize: "40px",
          paddingLeft: "80px",
          paddingTop: "20px",
          // marginTop: "200px"
        }}
      >
        {translate("whatIsRimbaudAI")}
      </div>
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "80px",
          paddingTop: "30px",
        }}
      >
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "justify",
          }}
        >
          {translate("rimbaudDescription")}
        </div>

        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "justify",
          }}
        >
          {translate("rimbaudCore")}
        </div>

        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "justify",
          }}
        >
          {translate("rimbaudAdvantages")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "20px",
            fontSize: "20px",
            textAlign: "justify",
          }}
        >
          {translate("rimbaudEssence")}
        </div>*/}
      </div>
    </div>
  );
};

export default LearnMoreContent;
