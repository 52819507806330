import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import StyledButtonEmptyMobile from "../components/buttons/StyledButtonEmptyMobile";
import StyledButtonMobile from "../components/buttons/StyledButtonMobile";
import { useAuth } from "../context/AuthContext";

const HomepageContentMobile = () => {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();
  const navigateTo = (path) => () => {
    navigate(path);
  };
  const { currentUser } = useAuth();

  return (
    <div className="homepage-content-mobile">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "24px",
          paddingLeft: "25px",
          paddingRight: "25px",
          textAlign: "center",
        }}
      >
        {translate("aiTool1")}
      </div>
      <div
        className="main-title-homepage"
        style={{
          fontSize: "24px",
          paddingLeft: "25px",
          paddingRight: "25px",
          textAlign: "center",
        }}
      >
        {translate("aiTool2")}
      </div>
      <div
        className="roboto-font"
        style={{
          fontSize: "16px",
          paddingTop: "35px",
          paddingLeft: "20px",
          paddingRight: "20px",
          textAlign: "center",
          width: "70%",
        }}
      >
        {translate("unleash")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "40px",
          width: "100%",
        }}
      >
        {!currentUser ? (
          <div
            style={{
              paddingRight: "0px",
              paddingBottom: "30px",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledButtonEmptyMobile onClick={navigateTo("/signup")}>
              {translate("getStarted")}
            </StyledButtonEmptyMobile>
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            paddingRight: "0px",
            paddingBottom: "0px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledButtonMobile onClick={navigateTo("/learnmore")}>
            {translate("learnMore")}
          </StyledButtonMobile>
        </div>
      </div>
    </div>
  );
};

export default HomepageContentMobile;
