const languages = [
  {
    id: 1,
    textDropDownEn: "English",
    code: "en",
  },
  {
    id: 2,
    textDropDownEn: "Français",
    code: "fr",
  },
  {
    id: 3,
    textDropDownEn: "Deutsch",
    code: "de",
  },
  {
    id: 4,
    textDropDownEn: "Español",
    code: "es",
  },
];

export default languages;
