import React from "react";
import googleIcon from "../../images/google-logo.png";

const SignUpButton = ({ onClick, children }) => {
  const imageStyle = {
    marginRight: "20px",
    width: "30px",
    height: "30px",
  };
  return (
    <button className="signInButton" onClick={onClick}>
      <img src={googleIcon} alt="Sign Up" style={imageStyle} />
      {children}
    </button>
  );
};

export default SignUpButton;
