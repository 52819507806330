import React, { useRef, useState } from "react";

const ToggleButtonGroupMobile = ({
  label,
  options,
  onSelectionChange,
  selectedValue,
}) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollContainerRef = useRef(null);

  const handleButtonClick = (value) => {
    onSelectionChange(value);
  };

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount =
        direction === "left"
          ? -scrollContainerRef.current.offsetWidth / 3
          : scrollContainerRef.current.offsetWidth / 3;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
      if (direction === "right") {
        setHasScrolled(true);
      } else if (scrollContainerRef.current.scrollLeft + scrollAmount <= 0) {
        setHasScrolled(false);
      }
    }
  };

  return (
    <div className="toggle-button-group">
      <div className="label-button-group-mobile2">{label}</div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {options.length > 4 && hasScrolled && (
          <div
            className="scroll-button-left"
            style={{ marginRight: "10px", marginLeft: "-0px" }}
            onClick={() => scroll("left")}
          >
            &lt;
          </div>
        )}
        <div className="button-container" ref={scrollContainerRef}>
          <div className="buttons">
            {options.map((option) => (
              <button
                key={option.value}
                className={`toggle-button-mobile ${
                  selectedValue === option.value ? "selected" : ""
                }`}
                onClick={() => handleButtonClick(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        {options.length > 4 && (
          <div
            className="scroll-button-right"
            style={{ marginLeft: "8px", fontSize: "18px" }}
            onClick={() => scroll("right")}
          >
            &gt;
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleButtonGroupMobile;
