import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { LanguageContext } from "../context/LanguageContext";
import LanguageIcon from "@mui/icons-material/Language";
import SignUpButtonMobile from "../components/buttons/SignUpButtonMobile";

const LoginContentMobile = () => {
  const navigate = useNavigate();
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const handleSignInClick = () => {
    navigate("/signup"); // Redirigez l'utilisateur vers /login
  };

  const { signInWithGoogle } = useAuth();

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  function handleSignInWithGoogle() {
    signInWithGoogle()
      .then((result) => {})
      .then(() => navigate("/"))
      .catch((error) => {});
  }

  return (
    <div className="signup-content-mobile">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          className="main-title-homepage"
          style={{
            fontSize: "25px",
            paddingBottom: "40px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          Rimbaud A.I.
        </div>
        <div
          className="main-title-signup"
          style={{
            fontSize: "20px",
            // marginTop: "200px"
            width: "100%",
            textAlign: "center",
            color: "white",
          }}
        >
          {translate("welcomeBack")}
        </div>

        <div
          style={{ fontSize: "14px", paddingTop: "20px", fontWeight: "200px" }}
        >
          {translate("loginToContinueWriting")}
        </div>

        <div style={{ paddingTop: "30px" }}>
          <SignUpButtonMobile onClick={handleSignInWithGoogle}>
            {translate("logInWithGoogle")}
          </SignUpButtonMobile>
        </div>
        <div style={{ fontSize: "10px", paddingTop: "30px" }}>
          {translate("dontHaveAccount")}
          <span
            style={{
              cursor: "pointer",
              paddingLeft: "5px",
              color: "#ab73ee",
            }}
            onClick={handleSignInClick}
          >
            {translate("signUp")}
          </span>
        </div>

        <div style={{ marginTop: "25px" }}>
          <LanguageIcon
            style={{ marginRight: "10px", verticalAlign: "middle" }}
          />
          <select
            value={language}
            style={{
              marginRight: "5px",
              backgroundColor: "transparent",
              color: "#767474",
              border: "1px solid #767474",
              borderRadius: "5px",
              padding: "3px 5px",
              outline: "none",
            }}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default LoginContentMobile;
