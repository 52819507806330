import React, { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { LanguageContext } from "../../context/LanguageContext";

const LineComponentMobile = ({ onClick, archiveData }) => {
  const { translate } = useContext(LanguageContext);

  return (
    <div className="toggle-button-group-bis">
      <div
        style={{
          width: "200px",
          height: "40px",
          backgroundColor: "#2A3038",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          {translate("title")}:
        </div>
        <div
          style={{
            width: "120px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "0px",
            color: "#ADADAD",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
          }}
        >
          {archiveData.title}
        </div>

        <div
          style={{
            width: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <EditIcon
            onClick={() => onClick(archiveData)}
            style={{ cursor: "pointer", fontSize: "16px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LineComponentMobile;
