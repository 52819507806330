import React from "react";
import googleIcon from "../../images/google-logo.png";

const SignUpButtonMobile = ({ onClick, children }) => {
  const imageStyle = {
    marginRight: "20px",
    width: "20px",
    height: "20px",
  };
  return (
    <button className="signInButtonMobile" onClick={onClick}>
      <img src={googleIcon} alt="Sign Up" style={imageStyle} />
      {children}
    </button>
  );
};

export default SignUpButtonMobile;
