import React from "react";
import NavbarMain from "./NavbarMain";
import Footer from "./Footer";
import SettingsContent from "./SettingsContent";
import { useMediaQuery } from "react-responsive";
import NavbarMainMobile from "./NavbarMainMobile";
import SettingsContentMobile from "./SettingsContentMobile";

const Settings = () => {
  const activeCategory = "Settings";
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div>
      {isMobile ? (
        <>
          <NavbarMainMobile activeCategory={activeCategory} />
          <SettingsContentMobile />
        </>
      ) : (
        <>
          <NavbarMain activeCategory={activeCategory} />
          <SettingsContent />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Settings;
