import React, { useState, useEffect } from "react";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { LanguageContext } from "./LanguageContext";
import { translations } from "../api/translations";
import { auth } from "../firebase";

export const LanguageProvider = ({ children }) => {
  const defaultLanguage = navigator.language.startsWith("fr")
    ? "fr"
    : navigator.language.startsWith("de")
    ? "de"
    : navigator.language.startsWith("es")
    ? "es"
    : "en";
  const [language, setLanguageState] = useState(defaultLanguage);
  console.log("Browser language:", defaultLanguage);
  const setLanguage = (newLanguage) => {
    setLanguageState(newLanguage);
  };
  console.log("Browser language:", defaultLanguage);
  console.log("language:", language);

  useEffect(() => {
    console.log("Updated language:", language);
  }, [language]); // Cette dépendance assure que le code s'exécute chaque fois que `language` change.

  useEffect(() => {
    let unsub;

    auth.onAuthStateChanged((user) => {
      if (user) {
        const db = getFirestore();
        const docRef = doc(db, "roles", user.uid);
        unsub = onSnapshot(docRef, (doc) => {
          if (doc.exists() && doc.data().language) {
            setLanguageState(doc.data().language);
          }
        });
      } else if (unsub) {
        unsub(); // Se désabonner lorsqu'il n'y a pas d'utilisateur connecté
      }
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, []);

  const translate = (key) => translations[language][key] || key;

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};
