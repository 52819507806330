import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { useAuth } from "../context/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { usePremiumStatus } from "../hooks/usePremiumStatus";
import { usePortalUrl } from "../hooks/usePortalUrl";
import { useCheckoutUrl } from "../hooks/useCheckoutUrl";
import PricingBoxMobile from "../components/misc/PricingBoxMobile";

const PricingContentMobile = () => {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [, setIsSelectedPlanBasic] = useState(false);
  const [isPremium] = usePremiumStatus();
  const { url: portalUrl, loading, error } = usePortalUrl();
  const priceId = "price_1P7XdrKxPduZsYMSK2h6rvZP";
  const [checkoutUrl, checkoutError] = useCheckoutUrl(priceId);

  const handlePortalRedirect = () => {
    if (portalUrl) {
      window.location.href = portalUrl;
    } else {
      console.error("Portal URL not available");
      alert("An error occurred while trying to access the Stripe portal.");
    }
  };

  const handleCheckoutClick = () => {
    if (checkoutError) {
      console.error(checkoutError);
      alert(
        "Une erreur est survenue lors de la tentative de paiement. Veuillez réessayer."
      );
    } else if (checkoutUrl) {
      window.location.href = checkoutUrl; // Rediriger l'utilisateur vers l'URL de paiement Stripe
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};

    if (currentUser) {
      const docRef = doc(db, "roles", currentUser.uid);

      unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            setIsSelectedPlanBasic(docSnap.data().role === "Free plan");
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error fetching role: ", error);
        }
      );
    }

    return () => unsubscribe(); // Cleanup the subscription on component unmount
  }, [currentUser]);

  return (
    <div className="pricing-content-mobile">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        {translate("pricing")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "50px",
        }}
      >
        <PricingBoxMobile
          link="#"
          planName={translate("freePlan")}
          planDetail={translate("getStartedDescription")}
          price="0"
          detailTitle1={translate("monthlyMailsLimit")}
          detail1={translate("craftEmailsDescription")}
          detailTitle2={translate("basicWritingAssistance")}
          detail2={translate("writingAssistanceDescription")}
          detailTitle3={translate("emailCustomization")}
          detail3={translate("basicTemplatesDescription")}
          // isSelectedPlan={isSelectedPlanBasic}
          isSelectedPlan={!isPremium}
          handleAction={isPremium ? handlePortalRedirect : undefined}
          isFreePlan={true}
          loading={loading}
          portalUrl={portalUrl}
          error={error}
        />
        <PricingBoxMobile
          link="#"
          planName={translate("premiumPlan")}
          planDetail={translate("elevateEmailGameDescription")}
          price="9"
          detailTitle1={translate("unlimitedEmailGeneration")}
          detail1={translate("sendEmailsDescription")}
          detailTitle2={translate("expandedStyleToneChoices")}
          detail2={translate("styleToneSelectionDescription")}
          detailTitle3={translate("advancedCustomizationOptions")}
          detail3={translate("enhancedToolsDescription")}
          // isSelectedPlan={!isSelectedPlanBasic}
          isSelectedPlan={isPremium}
          handleAction={!isPremium ? handleCheckoutClick : undefined}
          isFreePlan={false}
        />
      </div>
    </div>
  );
};

export default PricingContentMobile;
