import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import LottieLoader from "../components/misc/sasZb5q9fs.json";
import { LanguageContext } from "../context/LanguageContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, db } from "../firebase";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import language from "../api/language";
import { usePremiumStatus } from "../hooks/usePremiumStatus";
import { useAuth } from "../context/AuthContext";
import StyledButtonEmptyWithArrowMobile from "../components/buttons/StyledButtonEmptyWithArrowMobile";
import ToggleButtonGroupMobile from "../components/misc/ToggleButtonGroupMobile";
import TextInputAreaMobile from "../components/misc/TextInputAreaMobile";
import EmailSizeMobile from "../components/misc/EmailSizeMobile";
import GradientButtonMobile from "../components/buttons/GradientButtonMobile";
import TitleResultMobile from "../components/misc/TitleResultMobile";
import TextInputArea2Mobile from "../components/misc/TextInputArea2Mobile";

const GenerateContentMobile = () => {
  const { translate } = useContext(LanguageContext);
  const [mailStyle, setMailStyle] = useState("professional");
  const [mailTone, setMailTone] = useState("formal");
  const { currentUser } = useAuth();
  const [countWord, setCountWord] = useState(25);
  const [mailExplanation, setMailExplanation] = useState("");
  const [limitReached, setLimitReached] = useState(false);
  const [mailLanguage, setMailLanguage] = useState("English");
  const [generatedContent, setGeneratedContent] = useState("");
  const [generatedTitle, setGeneratedTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [archiveButtonText, setArchiveButtonText] = useState(
    translate("addToArchive")
  );
  const [emailCount, setEmailCount] = useState(0);
  const [isPremium] = usePremiumStatus();
  const { state } = useLocation();
  const archiveData = state?.archiveData;
  const navigate = useNavigate();
  const [showResults, setShowResults] = useState(false); // New state to toggle visibility

  const handleSelectionChange = (selectedValue) => {
    setMailStyle(selectedValue);
    console.log(selectedValue);
  };

  const handleLanguageChange = (selectedLanguage) => {
    setMailLanguage(selectedLanguage);
  };

  const handleClick2 = () => {
    navigate("/pricing");
  };

  useEffect(() => {
    if (currentUser) {
      const userStatRef = doc(db, "userStats", currentUser.uid);
      getDoc(userStatRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const lastReset = data.lastReset.toDate();
          const now = new Date();
          if (
            lastReset.getMonth() === now.getMonth() &&
            lastReset.getFullYear() === now.getFullYear()
          ) {
            setEmailCount(data.emailCount);
            setLimitReached(data.emailCount >= 10);
          } else {
            setDoc(
              userStatRef,
              { emailCount: 0, lastReset: serverTimestamp() },
              { merge: true }
            );
            setEmailCount(0);
            setLimitReached(false);
          }
        } else {
          setDoc(userStatRef, { emailCount: 0, lastReset: serverTimestamp() });
          setEmailCount(0);
          setLimitReached(false);
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (archiveData) {
      setMailStyle(archiveData.mailStyle);
      setMailTone(archiveData.mailTone);
      setCountWord(archiveData.countWord);
      setMailExplanation(archiveData.mailExplanation);
      setMailLanguage(archiveData.mailLanguage);
      setGeneratedContent(archiveData.content);
      setGeneratedTitle(archiveData.title);
    }
  }, [archiveData]);

  const getLanguageCodeFromName = (languageName) => {
    const languageObj = language.find(
      (option) => option.textDropDownEn === languageName
    );
    return languageObj ? languageObj.code : "";
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setArchiveButtonText(translate("addToArchive"));
  }, [translate]);

  const handleSelectionChangeTone = (selectedValue) => {
    setMailTone(selectedValue);
    console.log(selectedValue);
  };

  const addEmailToArchive = async () => {
    setIsLoading2(true);
    const user = auth.currentUser;

    if (!user) {
      console.error("User not authenticated");
      setIsLoading2(false);
      return;
    }

    const emailArchiveRef = doc(db, "archives", user.uid);
    const emailArchive = {
      timestamp: serverTimestamp(),
      title: generatedTitle,
      content: generatedContent,
      mailLanguage,
      mailTone,
      mailStyle,
      countWord,
      mailExplanation,
    };

    try {
      await setDoc(
        emailArchiveRef,
        { [new Date().getTime()]: emailArchive },
        { merge: true }
      );
      setArchiveButtonText(translate("addedToArchive"));
    } catch (error) {
      console.error("Failed to add email to archive:", error);
    }

    setIsLoading2(false);
  };

  const handleClick = () => {
    if (!isPremium && limitReached) {
      alert("You have reached the limit of email generations for this month.");
      return;
    }

    setIsLoading(true);

    // Assurez-vous d'avoir le UID de l'utilisateur actuel pour accéder à son document spécifique
    const userStatRef = doc(db, "userStats", currentUser.uid);

    // Vérification et mise à jour du compteur
    getDoc(userStatRef)
      .then((docSnap) => {
        const data = docSnap.data();
        if (!isPremium && data.emailCount >= 10) {
          setLimitReached(true);
          setIsLoading(false);
          alert("You have reached your email generation limit for this month.");
          return;
        }

        // Appels de fonction pour générer le contenu de l'email
        const functions = getFunctions();
        const generateEmailContentFunc = httpsCallable(
          functions,
          "generateEmailContent"
        );
        const generateEmailTitleFunc = httpsCallable(
          functions,
          "generateEmailTitle"
        );

        Promise.all([
          generateEmailContentFunc({
            mailStyle,
            mailTone,
            countWord,
            mailExplanation,
            mailLanguage,
          }),
          generateEmailTitleFunc({
            mailStyle,
            mailTone,
            countWord,
            mailExplanation,
            mailLanguage,
          }),
        ])
          .then(([contentResult, titleResult]) => {
            setGeneratedContent(contentResult.data.result);
            setGeneratedTitle(titleResult.data.result);

            // Incrément du compteur seulement après la génération réussie
            const newEmailCount = data.emailCount + 1;
            updateDoc(userStatRef, {
              emailCount: newEmailCount,
              lastReset: serverTimestamp(), // optionnellement mettre à jour la date du dernier reset si besoin
            }).then(() => {
              setEmailCount(newEmailCount);
              if (newEmailCount >= 10) {
                setLimitReached(true);
              }
              setIsLoading(false);
              setArchiveButtonText(translate("addToArchive"));
              setShowResults(true); // Show results and hide input controls
            });
          })
          .catch((error) => {
            console.error("Failed to generate content:", error);
            setIsLoading(false);
            setArchiveButtonText(translate("addToArchive"));
          });
      })
      .catch((error) => {
        console.error("Error accessing the user stats document:", error);
        setIsLoading(false);
      });
  };

  const handleChangeView = () => {
    setShowResults(!showResults); // Toggle view between input and results
  };

  const buttonOptions = {
    label: "What style?",
    options: [
      { label: translate("professional"), value: "professional" },
      { label: translate("friendly"), value: "friendly" },
      { label: translate("creative"), value: "creative" },
      { label: translate("promotional"), value: "promotional" },
      { label: translate("informative"), value: "informative" },
    ],
  };

  const toneOptions = {
    label: "What style?",
    options: [
      { label: translate("formal"), value: "formal" },
      { label: translate("humourous"), value: "humourous" },
      { label: translate("casual"), value: "casual" },
      { label: translate("persuasive"), value: "persuasive" },
      { label: translate("inspirational"), value: "inspirational" },
    ],
  };

  const openInGmail = () => {
    const subject = encodeURIComponent(generatedTitle);
    const body = encodeURIComponent(generatedContent);
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${subject}&body=${body}`;

    window.open(gmailUrl, "_blank");
  };

  return (
    <div className="pricing-content-mobile">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "24px",
          paddingLeft: "0px",
          paddingTop: "20px",
        }}
      >
        {translate("generateEmail")}
      </div>
      {!showResults ? (
        // Input controls
        <div>
          {limitReached && !isPremium && (
            <div
              style={{
                color: "red",
                marginTop: "20px",
                fontSize: "12px",
                paddingLeft: "0px",
              }}
            >
              {translate("emailGenerationLimitReached")}
              <br />
              {translate("remainingEmailsThisMonth")} {10 - emailCount}
            </div>
          )}
          {!limitReached && (
            <div
              style={{
                marginTop: "10px",
                fontSize: "12px",
                paddingLeft: "0px",
                textAlign: "center",
              }}
            >
              {translate("remainingEmailsThisMonth")} {10 - emailCount}
            </div>
          )}
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  paddingLeft: "0px",
                  paddingTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="label-button-group-mobile"
                  style={{
                    marginRight: "0px",
                  }}
                >
                  {translate("whatLanguage")}{" "}
                </div>
                <div style={{ paddingBottom: "5px" }}>
                  <StyledButtonEmptyWithArrowMobile
                    onSelectLanguage={handleLanguageChange}
                    selectedLanguageCode={getLanguageCodeFromName(mailLanguage)}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    width: "200px",
                  }}
                >
                  <ToggleButtonGroupMobile
                    label={translate("whatStyle")}
                    options={buttonOptions.options}
                    onSelectionChange={handleSelectionChange}
                    selectedValue={mailStyle}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    width: "200px",
                  }}
                >
                  <ToggleButtonGroupMobile
                    label={translate("whatTone")}
                    options={toneOptions.options}
                    onSelectionChange={handleSelectionChangeTone}
                    selectedValue={mailTone}
                  />
                </div>
              </div>

              <div
                style={{
                  paddingLeft: "00px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <TextInputAreaMobile
                  value={mailExplanation}
                  onChangeText={setMailExplanation}
                  label={translate("explainContent")}
                />
              </div>

              <div
                style={{
                  marginLeft: "0px",
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <EmailSizeMobile
                  countWord={countWord}
                  setCountWord={setCountWord}
                />
                <div
                  style={{
                    width: "150px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  {isLoading ? (
                    <Lottie options={defaultOptions} height={30} width={100} />
                  ) : (
                    <GradientButtonMobile
                      text={translate("generateEmail")}
                      onClick={handleClick}
                      disabled={limitReached && !isPremium}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Results display
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {!isLoading && (
            <div style={{ width: "80%", paddingTop: "20px" }}>
              <TitleResultMobile
                label={translate("subject")}
                content={generatedTitle}
              />
              <br />
              <TextInputArea2Mobile
                label={translate("textBody")}
                content={generatedContent}
              />
              <br />
              <div
                style={{
                  width: "95%",
                  display: "flex",
                  height: "30px",
                  justifyContent: "flex-start",
                }}
              >
                {isLoading2 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Lottie options={defaultOptions} height={30} width={100} />
                    <GradientButtonMobile
                      text={translate("sendInGmail")}
                      onClick={openInGmail}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!isPremium ? (
                        <GradientButtonMobile text={archiveButtonText} />
                      ) : (
                        <GradientButtonMobile
                          text={archiveButtonText}
                          onClick={addEmailToArchive}
                          disabled={
                            archiveButtonText === translate("addedToArchive")
                          }
                        />
                      )}

                      {!isPremium ? (
                        <div
                          style={{
                            paddingTop: "10px",
                            width: "180px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            textAlign: "center",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                          onClick={handleClick2}
                        >
                          {translate("availableOnPremiumPlan")}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!isPremium ? (
                        <GradientButtonMobile
                          text={translate("sendInGmail")}
                          // onClick={openInGmail}
                        />
                      ) : (
                        <GradientButtonMobile
                          text={translate("sendInGmail")}
                          onClick={openInGmail}
                        />
                      )}

                      {!isPremium ? (
                        <div
                          style={{
                            paddingTop: "10px",
                            width: "180px",
                            whiteSpace: "normal", // Assurez-vous que le texte peut passer à la ligne
                            wordWrap: "break-word",
                            textAlign: "center",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                          onClick={handleClick2}
                        >
                          {translate("availableOnPremiumPlan")}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "95%",
                  marginTop: "10px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GradientButtonMobile
                  text={translate("modify")}
                  onClick={handleChangeView}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateContentMobile;
