import React, { useState, useEffect } from "react";

const TextInputArea = ({ label, onChangeText, value }) => {
  const [text, setText] = useState(value);
  const maxLength = 1000;

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= maxLength) {
      setText(newValue);
      onChangeText(newValue);
    }
  };

  return (
    <div
      className="toggle-button-group-bis"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="label-button-group">{label}</div>
      <textarea
        className="input-text-area"
        value={text}
        onChange={handleChange}
        maxLength={maxLength}
      />

      <div
        className="character-count"
        style={{
          textAlign: "right",
          color: text.length < maxLength ? "green" : "red",
        }}
      >
        {text.length}/{maxLength}
      </div>
    </div>
  );
};

export default TextInputArea;
