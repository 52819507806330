import React from "react";
import NavbarMain from "./NavbarMain";
import Footer from "./Footer";
import PricingContent from "./PricingContent";
import NavbarMainMobile from "./NavbarMainMobile";
import { useMediaQuery } from "react-responsive";
import PricingContentMobile from "./PricingContentMobile";

const Pricing = () => {
  const activeCategory = "Pricing";
  const activeCategoryMobile = "pricing";
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div>
      {isMobile ? (
        <div style={{ width: "100%", height: "100%" }}>
          <NavbarMainMobile activeCategoryMobile={activeCategoryMobile} />
          <PricingContentMobile />
          {/* <FooterMobile /> */}
        </div>
      ) : (
        <>
          <NavbarMain activeCategory={activeCategory} />
          <PricingContent />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Pricing;
