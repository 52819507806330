import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const SuccessContent = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="learnmore-content">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "40px",
          paddingLeft: "0px",
          paddingTop: "20px",
          // marginTop: "200px"
        }}
      >
        {translate("purchaseConfirmationTitle")}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "0px",
          paddingTop: "50px",
          justifyContent: "center",
        }}
      >
        <div
          className="roboto-font"
          style={{
            paddingBottom: "25px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {translate("purchaseConfirmationMessage1")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "25px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {translate("purchaseConfirmationMessage2")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "25px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {translate("purchaseConfirmationMessage3")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "25px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {translate("purchaseConfirmationMessage4")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "25px",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {translate("purchaseConfirmationMessage5")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "25px",
            width: "100%",
            fontWeight: "400",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage6")}
        </div>
      </div>
    </div>
  );
};

export default SuccessContent;
