import React, { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { LanguageContext } from "../../context/LanguageContext";
import { format } from "date-fns";

const LineComponent = ({ onClick, archiveData }) => {
  const { translate } = useContext(LanguageContext);
  const formattedDate = format(archiveData.timestamp, "PPP");

  return (
    <div className="toggle-button-group-bis">
      <div
        style={{
          width: "800px",
          height: "40px",
          backgroundColor: "#2A3038",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {translate("title")}:
        </div>
        <div
          style={{
            width: "480px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "10px",
            color: "#ADADAD",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {archiveData.title}
        </div>

        <div
          style={{
            width: "160px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          {formattedDate}
        </div>
        <div
          style={{
            width: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <EditIcon
            onClick={() => onClick(archiveData)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LineComponent;
