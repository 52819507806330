import React, { useState } from "react";

const SmallTextInputNoBorder = ({ label, content, width }) => {
  const [, setText] = useState("");
  const maxLength = 40;

  const handleChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setText(event.target.value);
    }
  };

  return (
    <div
      className="toggle-button-group-bis"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="label-button-group-2">{label}</div>
      <textarea
        className="input-text-area-small-no-border "
        style={{ width: width }}
        value={content}
        onChange={handleChange}
        maxLength={maxLength}
      />
    </div>
  );
};

export default SmallTextInputNoBorder;
