import React from "react";
import NavbarMain from "./NavbarMain";
import Footer from "./Footer";
import GenerateContent from "./GenerateContent";
import { useMediaQuery } from "react-responsive";
import NavbarMainMobile from "./NavbarMainMobile";
import GenerateContentMobile from "./GenerateContentMobile";

const Generate = () => {
  const activeCategory = "Generate";
  const activeCategoryMobile = "generate";
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div>
      {isMobile ? (
        <div style={{ width: "100%", height: "100%" }}>
          <NavbarMainMobile activeCategoryMobile={activeCategoryMobile} />
          <GenerateContentMobile />
        </div>
      ) : (
        <>
          <NavbarMain activeCategory={activeCategory} />
          <GenerateContent />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Generate;
