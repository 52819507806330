import React, { useContext } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { LanguageContext } from "../../context/LanguageContext";
import poweredByStripe from "../../images/poweredbystripebis.png";
// import { useCheckoutUrl } from "../../hooks/useCheckoutUrl";

const PricingBoxMobile = ({
  planName,
  planDetail,
  price,
  detailTitle1,
  detail1,
  detailTitle2,
  detail2,
  detailTitle3,
  detail3,
  isSelectedPlan,
  isFreePlan,
  portalUrl,
  loading,
  error,
  handleAction,
}) => {
  const { translate } = useContext(LanguageContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "100%",
        paddingTop: "20px",
      }}
    >
      <div
        style={{
          width: "60%",
          height: "400px",
          border: "1px solid #ab73ee",
          borderRadius: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "10px",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "15%",
            borderBottom: "1px solid #54565A",
          }}
        >
          <div
            style={{ fontSize: "16px", paddingBottom: "5px", color: "white" }}
          >
            {planName}
          </div>
          <div className="roboto-font" style={{ fontSize: "12px" }}>
            {planDetail}
          </div>
        </div>
        <div
          className="roboto-font"
          style={{
            width: "100%",
            height: "35%",
            fontSize: "47px",
            fontWeight: "100",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid #54565A",
          }}
        >
          ${price}
          <span
            style={{
              fontSize: "29px",
              fontWeight: "100",
              paddingBottom: "20px",
            }}
          >
            /mo
          </span>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isFreePlan ? (
              <>
                {loading ? (
                  <div className="normalTextWhite">Loading...</div>
                ) : (
                  <div
                    className="kamerik-font button-pricing-mobile"
                    style={{
                      backgroundColor: isSelectedPlan ? "#ab73ee" : "#d0d6de",
                      color: isSelectedPlan ? "white" : "#6e7279",
                      cursor: portalUrl ? "pointer" : "default",
                    }}
                    onClick={portalUrl ? handleAction : undefined}
                  >
                    {isSelectedPlan
                      ? translate("selectedPlan")
                      : translate("chooseThisPlan")}
                  </div>
                )}
              </>
            ) : (
              <div
                className="kamerik-font button-pricing-mobile"
                style={{
                  backgroundColor: isSelectedPlan ? "#ab73ee" : "#d0d6de",
                  color: isSelectedPlan ? "white" : "#6e7279",
                  cursor: "pointer",
                }}
                onClick={handleAction}
              >
                {isSelectedPlan
                  ? translate("selectedPlan")
                  : translate("chooseThisPlan")}
              </div>
            )}
          </div>
        </div>
        <div style={{ width: "100%", height: "50%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "3px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon />
              <div className="title-detail-mobile">{detailTitle1}</div>
            </div>
            <div className="content-detail-mobile">{detail1}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "3px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon />
              <div className="title-detail-mobile">{detailTitle2}</div>
            </div>
            <div className="content-detail-mobile">{detail2}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "3px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon />
              <div className="title-detail-mobile">{detailTitle3}</div>
            </div>
            <div className="content-detail-mobile">{detail3}</div>
          </div>
          {!isFreePlan ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: "ponk",
                paddingTop: "10px",
              }}
            >
              <img
                src={poweredByStripe}
                className="stripe-logo-mobile"
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingBoxMobile;
