import React from "react";

const StyledButtonEmptyMobile = ({ onClick, children }) => {
  return (
    <button className="styledButtonEmptyMobile" onClick={onClick}>
      {children}
    </button>
  );
};

export default StyledButtonEmptyMobile;
