// translations.js
export const translations = {
  en: {
    home: "Home",
    unleash:
      "Unleash Your Email Potential with Rimbaut: AI-powered Email Generation Made Easy",
    aiTool1: "AI-Powered tool",
    aiTool2: "to easily generate email",
    generate: "Generate",
    library: "Library",
    pricing: "Pricing",
    settings: "Settings",
    joinNewsletter: "Join our newsletter",
    getUpdates:
      "Get the latest AI insights and updates directly to your inbox.",
    emailAddress: "Your email address",
    subscribe: "Subscribe",
    privacyPolicyAgree1: "By subscribing, you agree with our",
    privacyPolicyAgree2: "Privacy Policy",
    privacyPolicyTitle: "Privacy Policy for the Newsletter",
    introductionTitle: "Introduction",
    introductionContent:
      "At Rimbaud (5ideas Production Co., Ltd), we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide us when you sign up for our newsletter.",
    informationCollectionTitle: "Information Collection",
    informationCollectionContent:
      "When you sign up for our newsletter, we ask you to provide your email address. We may also collect additional information such as your name if you choose to provide it.",
    useOfInformationTitle: "Use of Information",
    useOfInformationContent:
      "The information we collect is used solely to send you the newsletter you have requested. Your email address and any other information provided will not be used for other purposes without your explicit consent.",
    informationSharingTitle: "Information Sharing",
    informationSharingContent:
      "Rimbaud (5ideas Production Co., Ltd) does not sell, rent, or share your personal information with third parties, except as required by law or with your explicit permission.",
    informationStorageTitle: "Information Storage",
    informationStorageContent:
      "Your personal information is stored on secure servers and is accessible only by a limited number of persons who have special access rights to such systems.",
    yourRightsTitle: "Your Rights",
    yourRightsContent:
      "At any time, you have the right to request access to the personal information we hold about you, request correction of incorrect or outdated information, withdraw your consent to the newsletter and request the deletion of your personal data from our database, and object to the processing of your personal data under certain circumstances.",
    privacyPolicyChangesTitle: "Privacy Policy Changes",
    privacyPolicyChangesContent:
      "We reserve the right to modify this privacy policy at any time. Any changes will be effective immediately upon their posting on our website.",
    contactTitle: "Contact",
    contactContent:
      "If you have any questions or concerns regarding this privacy policy or the processing of your personal data, please contact us at [contact email address].",
    displayedName: "Displayed Name",
    email: "Email",
    language: "Language",
    plan: "Plan",
    changePlan: "Change Plan",
    saving: "Saving...",
    getStarted: "Get started",
    learnMore: "Learn more",
    generateEmail: "Generate email",
    whatLanguage: "What language?",
    whatStyle: "What style?",
    professional: "Professional",
    friendly: "Friendly",
    promotional: "Promotional",
    informative: "Informative",
    creative: "Creative",
    whatTone: "What tone?",
    formal: "Formal",
    casual: "Casual",
    persuasive: "Persuasive",
    inspirational: "Inspirational",
    humourous: "Humourous",
    explainContent: "Quickly explain the content of the message",
    emailSize: "Email size",
    words: "words",
    subject: "Subject",
    textBody: "Text body",
    today: "Today",
    title: "Title",
    freePlan: "Free Plan",
    basicPlan: "Basic Plan",
    getStartedDescription:
      "Get started with AI-driven email creation at no cost.",
    elevateEmailGameDescription:
      "Elevate your email game with increased flexibility, perfect for professionals and small businesses.",
    monthlyMailsLimit: "Monthly Mails Limit",
    craftEmailsDescription:
      "Craft up to 10 emails per month, ideal for personal or small business use.",
    basicWritingAssistance: "Basic Writing Assistance",
    writingAssistanceDescription:
      "A variety of tones and styles at your fingertips for effective communication.",
    emailCustomization: "Email Customization",
    basicTemplatesDescription:
      "Basic templates to add a personal touch to your messages.",
    unlimitedEmailGeneration: "Unlimited Email Generation",
    sendEmailsDescription:
      "Send as many emails as you need, ensuring your communication never stops.",
    expandedStyleToneChoices: "Expanded Style and Tone Choices",
    styleToneSelectionDescription:
      "A wider selection of styles and tones to perfectly match every occasion and audience.",
    advancedCustomizationOptions: "Advanced Customization Options",
    enhancedToolsDescription:
      "Enhanced tools to personalize emails, making your brand's voice unmistakably yours.",
    whatIsRimbaudAI: "What is Rimbaud AI?",


    rimbaudIntroduction: "Discover Rimbaud, your intelligent creative assistant!",
    rimbaudRevolution: "Rimbaud revolutionizes content generation for everyone, thanks to artificial intelligence. Create emails and other content effortlessly, without technical expertise.",
    howItWorks: "How it works?",
    chooseYourStyle: "Choose your style:",
    chooseYourStyleDetail: "Select the tone and style of your content in a few clicks.",
    letAIHandleRest: "Let AI handle the rest:",
    letAIHandleRestDetail: "Our intelligent technology creates personalized and impactful messages based on your preferences.",
    easyToUse: "Easy to use:",
    easyToUseDetail: "Our user-friendly interface makes content creation accessible to everyone, regardless of your technical skill level.",
    whyChooseRimbaud: "Why choose Rimbaud?",
    simplicity: "Simplicity:",
    simplicityDetail: "No need to rack your brains, Rimbaud simplifies the entire process.",
    personalization: "Personalization:",
    personalizationDetail: "Your messages will be unique and tailored to your audience.",
    privacy: "Privacy:",
    privacyDetail: "We protect your personal data and ensure a hassle-free user experience.",
    joinRimbaudRevolution: "Join the Rimbaud revolution today and discover a new way of creating content, made possible by artificial intelligence.",



    rimbaudDescription:
      "Rimbaud is an AI-powered Software as a Service (SaaS) that revolutionizes content generation for individuals. It simplifies the creation of emails and various content types by harnessing advanced AI to offer a user-friendly platform. With Rimbaud, users effortlessly select their desired content's style, tone, and length through a straightforward interface, allowing the AI to tailor personalized and impactful messages.",
    rimbaudCore:
      "The core of Rimbaud is its intelligent engine, designed to understand and execute user preferences with precision, making content creation accessible to all, regardless of technical skills. Whether crafting professional emails, engaging social posts, or compelling articles, Rimbaud's AI ensures each piece resonates with its intended audience.",
    rimbaudAdvantages:
      "Rimbaud stands out by removing the complexity associated with AI-driven content creation, providing a seamless solution for those looking to elevate their communication. Ensuring privacy and prioritizing a seamless user experience, Rimbaud empowers users to leverage AI for their creative needs, making content creation not just easy but enjoyable.",
    rimbaudEssence:
      "In essence, Rimbaud democratizes AI content generation, offering a unique blend of simplicity, personalization, and efficiency. It's more than a tool; it's a revolution in how individuals create content, enabled by the power of artificial intelligence.",
    signUp: "Sign Up",
    logIn: "Log In",
    logInWithGoogle: "Log In with Google",
    dontHaveAccount: "Don't have an account?",
    alreadyHaveAccount: "Already have an account?",
    signUpWithGoogle: "Sign Up with Google",
    copyToClipboard: "Copy to clipboard",
    copied: "Copied!",
    addToArchive: "Add to archive",
    addedToArchive: "Added to archive",
    yesterday: "Yesterday",
    before: "Before",
    sendInGmail: "Send in Gmail",
    selectedPlan: "Selected plan",
    chooseThisPlan: "Choose this plan",
    aboutUs: "About us",
    createFreeAccount: "Create your free Rimbaut account",
    transformEmailExperience:
      "Transform Your Email Experience with AI Intelligence",
    welcomeBack: "Welcome back",
    loginToContinueWriting: "Login to continue writing",
    profile: "Profile",
    signOut: "Sign out",
    emailGenerationLimitReached:
      "Vous avez atteint la limite de génération d'emails pour votre plan actuel.",
    remainingEmailsThisMonth: "Remaining emails this month:",
    premiumPlan: "Premium Plan",
    availableOnPremiumPlan: "Available on Premium plan",
    modify: "Modify",
    archiveConversations: "Archive Conversations",
    oneClickGmailContent: "One-Click Sending of Gmail-Generated Content",
    archiveConversationsDetail:
      "Archive your conversations to revisit them later.",
    purchaseConfirmationTitle: "Purchase Confirmation",
    purchaseConfirmationMessage1: "Thank you for your purchase!",
    purchaseConfirmationMessage2:
      "Your transaction has been successfully processed.",
    purchaseConfirmationMessage3:
      "An email confirmation has been sent to your registered email address.",
    purchaseConfirmationMessage4:
      "You can now enjoy all the features and benefits of our service.",
    purchaseConfirmationMessage5:
      "If you have any questions or need further assistance, please do not hesitate to contact our support team.",
    purchaseConfirmationMessage6:
      "Feel free to continue browsing our services or visit your dashboard to see the details of your purchase.",

    oneClickGmailContentDetail:
      "Send Gmail-generated content in a single click, directly from our platform.",
    purchaseConfirmationTitle: "Purchase Confirmation",
    cancellationConfirmationTitle: "Subscription Cancellation Confirmation",
    cancellationConfirmationMessage1:
      "Your subscription cancellation has been processed successfully.",
    cancellationConfirmationMessage2: "We're sorry to see you go!",
    cancellationConfirmationMessage3:
      "If there was anything about our service that didn't meet your expectations, we'd love to hear your feedback.",
    cancellationConfirmationMessage4:
      "Your input is invaluable as it helps us improve and better serve our customers in the future.",
    cancellationConfirmationMessage5:
      "If you change your mind or if there's anything we can do to assist you further, please don't hesitate to reach back out.",
    cancellationConfirmationMessage6:
      "Remember, you can always resubscribe or explore other services we offer at any time.",
  },

  es: {
    home: "Inicio",
    unleash:
      "Desata tu potencial de correo electrónico con Rimbaut: generación de correo electrónico impulsada por IA de manera sencilla",
    aiTool1: "Herramienta impulsada por IA",
    aiTool2: "para generar correos fácilmente",
    generate: "Generar",
    library: "Biblioteca",
    pricing: "Precios",
    settings: "Configuraciones",
    joinNewsletter: "Únete a nuestro boletín",
    getUpdates:
      "Recibe las últimas novedades y actualizaciones de IA directamente en tu bandeja de entrada.",
    emailAddress: "Tu dirección de correo",
    subscribe: "Suscribirse",
    privacyPolicyAgree1: "Al suscribirte, aceptas nuestra",
    privacyPolicyAgree2: "Política de Privacidad",
    privacyPolicyTitle: "Política de Privacidad para el Boletín",
    introductionTitle: "Introducción",
    introductionContent:
      "En Rimbaud (5ideas Production Co., Ltd), estamos comprometidos a proteger su privacidad. Esta Política de Privacidad describe cómo recopilamos, utilizamos, divulgamos y protegemos la información que nos proporciona cuando se suscribe a nuestro boletín.",
    informationCollectionTitle: "Recolección de Información",
    informationCollectionContent:
      "Cuando se suscribe a nuestro boletín, le pedimos que proporcione su dirección de correo electrónico. También podemos recopilar información adicional, como su nombre, si decide proporcionarlo.",
    useOfInformationTitle: "Uso de la Información",
    useOfInformationContent:
      "La información que recopilamos se utiliza únicamente para enviarle el boletín que ha solicitado. Su dirección de correo electrónico y cualquier otra información proporcionada no se utilizarán para otros fines sin su consentimiento explícito.",
    informationSharingTitle: "Compartir Información",
    informationSharingContent:
      "Rimbaud (5ideas Production Co., Ltd) no vende, alquila ni comparte su información personal con terceros, excepto según lo requerido por ley o con su permiso explícito.",
    informationStorageTitle: "Almacenamiento de Información",
    informationStorageContent:
      "Su información personal se almacena en servidores seguros y es accesible solo por un número limitado de personas que tienen derechos de acceso especiales a dichos sistemas.",
    yourRightsTitle: "Sus Derechos",
    yourRightsContent:
      "En cualquier momento, tiene el derecho de solicitar acceso a la información personal que tenemos sobre usted, solicitar la corrección de información incorrecta o desactualizada, retirar su consentimiento al boletín y solicitar la eliminación de sus datos personales de nuestra base de datos, y objetar el procesamiento de sus datos personales bajo ciertas circunstancias.",
    privacyPolicyChangesTitle: "Cambios en la Política de Privacidad",
    privacyPolicyChangesContent:
      "Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en nuestro sitio web.",
    contactTitle: "Contacto",
    contactContent:
      "Si tiene alguna pregunta o inquietud respecto a esta política de privacidad o el procesamiento de sus datos personales, por favor contáctenos en [dirección de correo electrónico de contacto].",
    displayedName: "Nombre mostrado",
    email: "Correo electrónico",
    language: "Idioma",
    plan: "Plan",
    changePlan: "Cambiar plan",
    saving: "Guardando...",
    getStarted: "Empezar",
    learnMore: "Aprender más",
    generateEmail: "Generar correo",
    whatLanguage: "¿Qué idioma?",
    whatStyle: "¿Qué estilo?",
    professional: "Profesional",
    friendly: "Amistoso",
    promotional: "Promocional",
    informative: "Informativo",
    creative: "Creativo",
    whatTone: "¿Qué tono?",
    formal: "Formal",
    casual: "Informal",
    persuasive: "Persuasivo",
    inspirational: "Inspirador",
    humourous: "Divertido",
    explainContent: "Explique rápidamente el contenido del mensaje",
    emailSize: "Tamaño del correo",
    words: "palabras",
    subject: "Asunto",
    textBody: "Cuerpo del texto",
    today: "Hoy",
    title: "Título",
    freePlan: "Plan Gratuito",
    basicPlan: "Plan Básico",
    getStartedDescription:
      "Comience con la creación de correos electrónicos impulsados por IA sin costo alguno.",
    elevateEmailGameDescription:
      "Eleve su juego de correos electrónicos con mayor flexibilidad, perfecto para profesionales y pequeñas empresas.",
    monthlyMailsLimit: "Límite de Correos Mensuales",
    craftEmailsDescription:
      "Cree hasta 10 correos electrónicos por mes, ideal para uso personal o pequeñas empresas.",
    basicWritingAssistance: "Asistencia Básica para la Escritura",
    writingAssistanceDescription:
      "Una variedad de tonos y estilos a su alcance para una comunicación efectiva.",
    emailCustomization: "Personalización de Correo",
    basicTemplatesDescription:
      "Plantillas básicas para añadir un toque personal a sus mensajes.",
    unlimitedEmailGeneration: "Generación de Correos Ilimitada",
    sendEmailsDescription:
      "Envíe tantos correos como necesite, asegurando que su comunicación nunca se detenga.",
    expandedStyleToneChoices: "Amplia Selección de Estilos y Tonos",
    styleToneSelectionDescription:
      "Una selección más amplia de estilos y tonos para adaptarse perfectamente a cada ocasión y audiencia.",
    advancedCustomizationOptions: "Opciones de Personalización Avanzadas",
    enhancedToolsDescription:
      "Herramientas mejoradas para personalizar correos electrónicos, haciendo que la voz de su marca sea inconfundiblemente suya.",
    whatIsRimbaudAI: "¿Qué es Rimbaud AI?",
    rimbaudDescription:
      "Rimbaud es un Software como Servicio (SaaS) potenciado por IA que revoluciona la generación de contenido para individuos. Simplifica la creación de correos electrónicos y diversos tipos de contenido aprovechando la IA avanzada para ofrecer una plataforma amigable al usuario. Con Rimbaud, los usuarios seleccionan sin esfuerzo el estilo, tono y longitud del contenido deseado a través de una interfaz sencilla, permitiendo que la IA adapte mensajes personalizados e impactantes.",
    rimbaudCore:
      "El núcleo de Rimbaud es su motor inteligente, diseñado para entender y ejecutar las preferencias de los usuarios con precisión, haciendo que la creación de contenido sea accesible para todos, independientemente de las habilidades técnicas. Ya sea redactando correos electrónicos profesionales, publicaciones sociales atractivas o artículos convincentes, la IA de Rimbaud asegura que cada pieza resuene con su audiencia prevista.",
    rimbaudAdvantages:
      "Rimbaud se destaca por eliminar la complejidad asociada con la creación de contenido impulsada por IA, proporcionando una solución sin interrupciones para aquellos que buscan elevar su comunicación. Asegurando la privacidad y priorizando una experiencia de usuario impecable, Rimbaud empodera a los usuarios para aprovechar la IA para sus necesidades creativas, haciendo que la creación de contenido no solo sea fácil sino también agradable.",
    rimbaudEssence:
      "En esencia, Rimbaud democratiza la generación de contenido de IA, ofreciendo una mezcla única de simplicidad, personalización y eficiencia. Es más que una herramienta; es una revolución en cómo los individuos crean contenido, habilitado por el poder de la inteligencia artificial.",
      rimbaudIntroduction: "¡Descubre Rimbaud, tu asistente creativo inteligente!",
      rimbaudRevolution: "Rimbaud revoluciona la generación de contenido para todos, gracias a la inteligencia artificial. Crea correos electrónicos y otros contenidos sin esfuerzo, sin experiencia técnica.",
      howItWorks: "¿Cómo funciona?",
      chooseYourStyle: "Elige tu estilo:",
      chooseYourStyleDetail: "Selecciona el tono y el estilo de tu contenido en unos pocos clics.",
      letAIHandleRest: "Deja que la IA se encargue del resto:",
      letAIHandleRestDetail: "Nuestra tecnología inteligente crea mensajes personalizados e impactantes basados en tus preferencias.",
      easyToUse: "Fácil de usar:",
      easyToUseDetail: "Nuestra interfaz fácil de usar hace que la creación de contenido sea accesible para todos, independientemente de tu nivel de habilidad técnica.",
      whyChooseRimbaud: "¿Por qué elegir Rimbaud?",
      simplicity: "Simplicidad:",
      simplicityDetail: "No necesitas romperte la cabeza, Rimbaud simplifica todo el proceso.",
      personalization: "Personalización:",
      personalizationDetail: "Tus mensajes serán únicos y adaptados a tu audiencia.",
      privacy: "Privacidad:",
      privacyDetail: "Protegemos tus datos personales y garantizamos una experiencia de usuario sin problemas.",
      joinRimbaudRevolution: "Únete a la revolución Rimbaud hoy y descubre una nueva forma de crear contenido, gracias a la inteligencia artificial.",
     
    
      signUp: "Registrarse",
    logIn: "Iniciar sesión",
    logInWithGoogle: "Iniciar sesión con Google",
    dontHaveAccount: "¿No tienes una cuenta?",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
    signUpWithGoogle: "Registrarse con Google",
    copyToClipboard: "Copiar al portapapeles",
    copied: "¡Copiado!",
    addToArchive: "Agregar al archivo",
    addedToArchive: "Agregado al archivo",
    yesterday: "Ayer",
    before: "Antes",
    sendInGmail: "Enviar en Gmail",
    selectedPlan: "Plan seleccionado",
    chooseThisPlan: "Elegir este plan",
    aboutUs: "Sobre nosotros",
    createFreeAccount: "Crea tu cuenta gratuita en Rimbaut",
    transformEmailExperience:
      "Transforma tu experiencia de correo electrónico con la inteligencia artificial",
    welcomeBack: "Bienvenido de nuevo",
    loginToContinueWriting: "Inicia sesión para seguir escribiendo",
    profile: "Perfil",
    signOut: "Cerrar sesión",
    emailGenerationLimitReached:
      "Ha alcanzado el límite de generación de correos electrónicos para su plan actual.",
    remainingEmailsThisMonth: "Correos electrónicos restantes este mes:",
    premiumPlan: "Plan Premium",
    availableOnPremiumPlan: "Disponible en el plan Premium",
    modify: "Modificar",
    archiveConversations: "Archivar Conversaciones",
    oneClickGmailContent: "Envío en un clic del contenido generado por Gmail",
    archiveConversationsDetail:
      "Archive sus conversaciones para volver a ellas más tarde.",
    oneClickGmailContentDetail:
      "Envíe el contenido generado por Gmail con un solo clic, directamente desde nuestra plataforma.",
    purchaseConfirmationTitle: "Confirmación de Compra",
    purchaseConfirmationTitle: "Confirmación de Compra",
    purchaseConfirmationMessage1: "¡Gracias por su compra!",
    purchaseConfirmationMessage2: "Su transacción ha sido procesada con éxito.",
    purchaseConfirmationMessage3:
      "Se ha enviado una confirmación por correo electrónico a su dirección de correo electrónico registrada.",
    purchaseConfirmationMessage4:
      "Ahora puede disfrutar de todas las funciones y beneficios de nuestro servicio.",
    purchaseConfirmationMessage5:
      "Si tiene alguna pregunta o necesita más ayuda, no dude en ponerse en contacto con nuestro equipo de soporte.",
    purchaseConfirmationMessage6:
      "Siéntase libre de seguir navegando por nuestros servicios o de visitar su panel para ver los detalles de su compra.",

    cancellationConfirmationTitle: "Confirmación de Cancelación de Suscripción",
    cancellationConfirmationMessage1:
      "Su cancelación de suscripción se ha procesado con éxito.",
    cancellationConfirmationMessage2: "¡Lamentamos verte ir!",
    cancellationConfirmationMessage3:
      "Si hubo algo sobre nuestro servicio que no cumplió con sus expectativas, nos encantaría conocer sus comentarios.",
    cancellationConfirmationMessage4:
      "Su aporte es invaluable ya que nos ayuda a mejorar y a servir mejor a nuestros clientes en el futuro.",
    cancellationConfirmationMessage5:
      "Si cambia de opinión o si hay algo que podamos hacer para ayudarlo aún más, no dude en volver a contactarnos.",
    cancellationConfirmationMessage6:
      "Recuerde que siempre puede volver a suscribirse o explorar otros servicios que ofrecemos en cualquier momento.",
  },

  fr: {
    home: "Accueil",
    unleash:
      "Libérez votre potentiel de rédaction d'email avec Rimbaut : la génération d'email assistée par IA, rendue facile",
    aiTool1: "Outil piloté par IA",
    aiTool2: "pour générer facilement des e-mails",
    generate: "Générer",
    library: "Bibliothèque",
    pricing: "Tarification",
    settings: "Paramètres",
    joinNewsletter: "Inscrivez vous à notre newsletter",
    getUpdates:
      "Recevez les dernières informations et mises à jour sur Rimbaud directement dans votre boîte de réception.",
    emailAddress: "Votre adresse email",
    subscribe: "S'abonner",
    privacyPolicyAgree1: "En vous abonnant, vous acceptez notre",
    privacyPolicyAgree2: "Politique de Confidentialité",
    privacyPolicyTitle: "Politique de Confidentialité pour la Newsletter",
    introductionTitle: "Introduction",
    introductionContent:
      "Chez Rimbaud (5ideas Production Co., Ltd), nous nous engageons à protéger votre vie privée. Cette Politique de Confidentialité décrit comment nous collectons, utilisons, divulguons et protégeons les informations que vous nous fournissez lorsque vous vous inscrivez à notre newsletter.",
    informationCollectionTitle: "Collecte d'Informations",
    informationCollectionContent:
      "Lorsque vous vous inscrivez à notre newsletter, nous vous demandons de fournir votre adresse e-mail. Nous pouvons également collecter des informations supplémentaires telles que votre nom si vous choisissez de le fournir.",
    useOfInformationTitle: "Utilisation des Informations",
    useOfInformationContent:
      "Les informations que nous collectons sont utilisées uniquement pour vous envoyer la newsletter que vous avez demandée. Votre adresse e-mail et toute autre information fournie ne seront pas utilisées à d'autres fins sans votre consentement explicite.",
    informationSharingTitle: "Partage d'Informations",
    informationSharingContent:
      "Rimbaud (5ideas Production Co., Ltd) ne vend, ne loue et ne partage pas vos informations personnelles avec des tiers, sauf si requis par la loi ou avec votre permission explicite.",
    informationStorageTitle: "Stockage des Informations",
    informationStorageContent:
      "Vos informations personnelles sont stockées sur des serveurs sécurisés et ne sont accessibles que par un nombre limité de personnes ayant des droits d'accès spéciaux à ces systèmes.",
    yourRightsTitle: "Vos Droits",
    yourRightsContent:
      "À tout moment, vous avez le droit de demander l'accès aux informations personnelles que nous détenons sur vous, de demander la correction d'informations incorrectes ou obsolètes, de retirer votre consentement à la newsletter et de demander la suppression de vos données personnelles de notre base de données, et de vous opposer au traitement de vos données personnelles dans certaines circonstances.",
    privacyPolicyChangesTitle:
      "Modifications de la Politique de Confidentialité",
    privacyPolicyChangesContent:
      "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Tous les changements seront effectifs immédiatement après leur publication sur notre site Web.",
    contactTitle: "Contact",
    contactContent:
      "Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité ou le traitement de vos données personnelles, veuillez nous contacter à [adresse e-mail de contact].",
    displayedName: "Nom affiché",
    email: "Email",
    language: "Langue",
    plan: "Forfait",
    changePlan: "Changer de forfait",
    saving: "Enregistrement...",
    getStarted: "Commencer",
    learnMore: "En savoir plus",
    generateEmail: "Générer un email",
    whatLanguage: "Quelle langue ?",
    whatStyle: "Quel style ?",
    professional: "Professionnel",
    friendly: "Amical",
    promotional: "Promotionnel",
    informative: "Informatif",
    creative: "Créatif",
    whatTone: "Quel ton ?",
    formal: "Formel",
    casual: "Décontracté",
    persuasive: "Persuasif",
    inspirational: "Inspirant",
    humourous: "Humoristique",
    explainContent: "Expliquez rapidement le contenu du message",
    emailSize: "Taille de l'email",
    words: "mots",
    subject: "Sujet",
    textBody: "Corps du texte",
    today: "Aujourd'hui",
    title: "Titre",
    freePlan: "Plan Gratuit",
    basicPlan: "Plan Basique",
    getStartedDescription:
      "Commencez à créer des e-mails pilotés par l'IA sans aucun coût.",
    elevateEmailGameDescription:
      "Élevez votre jeu d'e-mails avec plus de flexibilité, parfait pour les professionnels et les petites entreprises.",
    monthlyMailsLimit: "Limite de Mails Mensuels",
    craftEmailsDescription:
      "Rédigez jusqu'à 10 e-mails par mois, idéal pour un usage personnel ou pour les petites entreprises.",
    basicWritingAssistance: "Assistance à la Rédaction Basique",
    writingAssistanceDescription:
      "Une variété de tons et de styles à portée de main pour une communication efficace.",
    emailCustomization: "Personnalisation d'E-mail",
    basicTemplatesDescription:
      "Des modèles de base pour personnaliser vos messages.",
    unlimitedEmailGeneration: "Génération d'E-mails Illimitée",
    sendEmailsDescription:
      "Envoyez autant d'e-mails que nécessaire, assurant que votre communication ne s'arrête jamais.",
    expandedStyleToneChoices: "Choix de Style et de Ton Élargis",
    styleToneSelectionDescription:
      "Une sélection plus large de styles et de tons pour correspondre parfaitement à chaque occasion et public.",
    advancedCustomizationOptions: "Options de Personnalisation Avancées",

    rimbaudIntroduction: "Découvrez Rimbaud, votre assistant créatif intelligent !",
    rimbaudRevolution: "Rimbaud révolutionne la génération de contenu pour tous, grâce à l'intelligence artificielle. Créez des emails et d'autres contenus en toute simplicité, sans expertise technique.",
    howItWorks: "Comment ça marche ?",
    chooseYourStyle: "Choisissez votre style:",
    chooseYourStyleDetail: "Sélectionnez le ton et le style de votre contenu en quelques clics.",
    letAIHandleRest: "Laissez l'IA faire le reste:",
    letAIHandleRestDetail: "Notre technologie intelligente crée des messages personnalisés et impactants en fonction de vos préférences.",
    easyToUse: "Facile à utiliser:",
    easyToUseDetail: "Notre interface conviviale rend la création de contenu accessible à tous, quel que soit votre niveau de compétence technique.",
    whyChooseRimbaud: "Pourquoi choisir Rimbaud ?",
    simplicity: "Simplicité:",
    simplicityDetail: "Plus besoin de se creuser la tête, Rimbaud simplifie tout le processus.",
    personalization: "Personnalisation:",
    personalizationDetail: "Vos messages seront uniques et adaptés à votre audience.",
    privacy: "Confidentialité:",
    privacyDetail: "Nous protégeons vos données personnelles et assurons une expérience utilisateur sans tracas.",
    joinRimbaudRevolution: "Rejoignez la révolution Rimbaud dès aujourd'hui et découvrez une nouvelle façon de créer du contenu, rendue possible par l'intelligence artificielle.",
    
    enhancedToolsDescription:
      "Des outils améliorés pour personnaliser les e-mails, rendant la voix de votre marque incontestablement la vôtre.",
    whatIsRimbaudAI: "Qu'est-ce que Rimbaud AI ?",
    rimbaudDescription:
      "Rimbaud est un logiciel en tant que service (SaaS) alimenté par l'IA qui révolutionne la génération de contenu pour les individus. Il simplifie la création d'e-mails et de divers types de contenu en exploitant une IA avancée pour offrir une plateforme conviviale. Avec Rimbaud, les utilisateurs sélectionnent sans effort le style, le ton et la longueur du contenu souhaité via une interface simple, permettant à l'IA de personnaliser des messages percutants et personnalisés.",
    rimbaudCore:
      "Le cœur de Rimbaud est son moteur intelligent, conçu pour comprendre et exécuter les préférences des utilisateurs avec précision, rendant la création de contenu accessible à tous, quelles que soient les compétences techniques. Que ce soit pour rédiger des e-mails professionnels, des publications sociales engageantes ou des articles convaincants, l'IA de Rimbaud garantit que chaque pièce résonne avec son public cible.",
    rimbaudAdvantages:
      "Rimbaud se distingue en éliminant la complexité associée à la création de contenu pilotée par l'IA, offrant une solution transparente pour ceux qui cherchent à améliorer leur communication. En garantissant la confidentialité et en priorisant une expérience utilisateur sans faille, Rimbaud permet aux utilisateurs de tirer parti de l'IA pour leurs besoins créatifs, rendant la création de contenu non seulement facile mais aussi agréable.",
    rimbaudEssence:
      "En essence, Rimbaud démocratise la génération de contenu IA, offrant un mélange unique de simplicité, de personnalisation et d'efficacité. C'est plus qu'un outil ; c'est une révolution dans la manière dont les individus créent du contenu, activée par la puissance de l'intelligence artificielle.",
    signUp: "S'inscrire",
    logIn: "Se connecter",
    logInWithGoogle: "Se connecter avec Google",
    dontHaveAccount: "Vous n'avez pas de compte ?",
    alreadyHaveAccount: "Vous avez déjà un compte ?",
    signUpWithGoogle: "S'inscrire avec Google",
    copyToClipboard: "Copier dans le presse-papier",
    copied: "Copié !",
    addToArchive: "Ajouter à la bibliothèque",
    addedToArchive: "Ajouté !",
    yesterday: "Hier",
    before: "Avant",
    sendInGmail: "Envoyer via Gmail",
    selectedPlan: "Forfait sélectionné",
    chooseThisPlan: "Choisir ce forfait",
    aboutUs: "À propos",
    createFreeAccount: "Créez votre compte Rimbaut gratuit",
    transformEmailExperience:
      "Transformez votre expérience e-mail avec l'intelligence artificielle",
    welcomeBack: "Content de te revoir",
    loginToContinueWriting: "Connectez-vous pour continuer à écrire",
    profile: "Profil",
    signOut: "Déconnexion",
    emailGenerationLimitReached:
      "Vous avez atteint la limite de génération d'emails pour votre plan actuel.",
    remainingEmailsThisMonth: "E-mails restants ce mois-ci :",
    premiumPlan: "Plan Premium",
    availableOnPremiumPlan: "Disponible dans le plan Premium",
    modify: "Modifier",
    archiveConversations: "Archiver les conversations",
    oneClickGmailContent: "Envoi en un clic du contenu généré par Gmail",
    archiveConversationsDetail:
      "Archivez vos conversations pour y revenir ultérieurement.",
    oneClickGmailContentDetail:
      "Envoyez le contenu généré par Gmail en un seul clic, directement depuis notre plateforme.",
    purchaseConfirmationTitle: "Confirmation d'achat",
    purchaseConfirmationMessage1: "Merci pour votre achat !",
    purchaseConfirmationMessage2:
      "Votre transaction a été traitée avec succès.",
    purchaseConfirmationMessage3:
      "Une confirmation par e-mail a été envoyée à votre adresse e-mail enregistrée.",
    purchaseConfirmationMessage4:
      "Vous pouvez désormais profiter de toutes les fonctionnalités et avantages de notre service.",
    purchaseConfirmationMessage5:
      "Si vous avez des questions ou avez besoin d'une assistance supplémentaire, n'hésitez pas à contacter notre équipe d'assistance.",
    purchaseConfirmationMessage6:
      "N'hésitez pas à continuer à parcourir nos services ou à visiter votre tableau de bord pour voir les détails de votre achat.",

    cancellationConfirmationTitle:
      "Confirmation de l'annulation de l'abonnement",
    cancellationConfirmationMessage1:
      "Votre annulation d'abonnement a été traitée avec succès.",
    cancellationConfirmationMessage2:
      "Nous sommes désolés de vous voir partir !",
    cancellationConfirmationMessage3:
      "Si quelque chose concernant notre service n'a pas répondu à vos attentes, nous aimerions entendre vos commentaires.",
    cancellationConfirmationMessage4:
      "Votre avis est précieux car il nous aide à nous améliorer et à mieux servir nos clients à l'avenir.",
    cancellationConfirmationMessage5:
      "Si vous changez d'avis ou si nous pouvons faire quelque chose pour vous aider davantage, n'hésitez pas à nous contacter à nouveau.",
    cancellationConfirmationMessage6:
      "N'oubliez pas que vous pouvez toujours vous réabonner ou explorer d'autres services que nous proposons à tout moment.",
  },

  de: {
    home: "Start",
    unleash:
      "Entfesseln Sie Ihr E-Mail-Potenzial mit Rimbaut: E-Mail-Erstellung mit KI-Leichtigkeit",
    aiTool1: "KI-gestütztes Tool",
    aiTool2: "zur einfachen Erzeugung von E-Mails",
    generate: "Erzeugen",
    library: "Bibliothek",
    pricing: "Preisgestaltung",
    settings: "Einstellungen",
    joinNewsletter: "Abonnieren Sie unseren Newsletter",
    getUpdates:
      "Erhalten Sie die neuesten KI-Einblicke und Updates direkt in Ihrem Posteingang.",
    emailAddress: "Ihre E-Mail-Adresse",
    subscribe: "Abonnieren",
    privacyPolicyAgree1: "Mit dem Abonnement stimmen Sie unserer",
    privacyPolicyAgree2: "Datenschutzrichtlinie zu",
    privacyPolicyTitle: "Datenschutzrichtlinie für den Newsletter",
    introductionTitle: "Einführung",
    introductionContent:
      "Bei Rimbaud (5ideas Production Co., Ltd) verpflichten wir uns zum Schutz Ihrer Privatsphäre. Diese Datenschutzrichtlinie erläutert, wie wir Ihre Daten sammeln, verwenden, offenlegen und schützen, wenn Sie sich für unseren Newsletter anmelden.",
    informationCollectionTitle: "Datenerfassung",
    informationCollectionContent:
      "Wenn Sie sich für unseren Newsletter anmelden, bitten wir Sie, Ihre E-Mail-Adresse anzugeben. Wir können auch zusätzliche Informationen wie Ihren Namen erfassen, falls Sie sich entscheiden, diese bereitzustellen.",
    useOfInformationTitle: "Verwendung der Informationen",
    useOfInformationContent:
      "Die von uns gesammelten Informationen werden ausschließlich dazu verwendet, Ihnen den angeforderten Newsletter zu senden. Ihre E-Mail-Adresse und alle anderen bereitgestellten Informationen werden ohne Ihre ausdrückliche Zustimmung nicht für andere Zwecke verwendet.",
    informationSharingTitle: "Weitergabe von Informationen",
    informationSharingContent:
      "Rimbaud (5ideas Production Co., Ltd) verkauft, vermietet oder teilt Ihre persönlichen Informationen nicht mit Dritten, außer wie gesetzlich erforderlich oder mit Ihrer ausdrücklichen Erlaubnis.",
    informationStorageTitle: "Informationsspeicherung",
    informationStorageContent:
      "Ihre persönlichen Informationen werden auf sicheren Servern gespeichert und sind nur einer begrenzten Anzahl von Personen zugänglich, die spezielle Zugriffsrechte auf solche Systeme haben.",
    yourRightsTitle: "Ihre Rechte",
    yourRightsContent:
      "Sie haben jederzeit das Recht, Zugang zu den persönlichen Informationen zu beantragen, die wir über Sie speichern, die Korrektur von falschen oder veralteten Informationen zu verlangen, Ihre Zustimmung zum Newsletter zurückzuziehen und die Löschung Ihrer persönlichen Daten aus unserer Datenbank zu fordern sowie der Verarbeitung Ihrer persönlichen Daten unter bestimmten Umständen zu widersprechen.",
    privacyPolicyChangesTitle: "Änderungen der Datenschutzrichtlinie",
    privacyPolicyChangesContent:
      "Wir behalten uns das Recht vor, diese Datenschutzrichtlinie jederzeit zu ändern. Änderungen treten sofort mit ihrer Veröffentlichung auf unserer Website in Kraft.",
    contactTitle: "Kontakt",
    contactContent:
      "Wenn Sie Fragen oder Bedenken bezüglich dieser Datenschutzrichtlinie oder der Verarbeitung Ihrer persönlichen Daten haben, kontaktieren Sie uns bitte unter [Kontakt-E-Mail-Adresse].",
    displayedName: "Angezeigter Name",
    email: "E-Mail",
    language: "Sprache",
    plan: "Tarif",
    changePlan: "Tarif wechseln",
    saving: "Speichern...",
    getStarted: "Loslegen",
    learnMore: "Mehr erfahren",
    generateEmail: "E-Mail erstellen",
    whatLanguage: "Welche Sprache?",
    whatStyle: "Welcher Stil?",
    professional: "Professionell",
    friendly: "Freundlich",
    promotional: "Werblich",
    informative: "Informativ",
    creative: "Kreativ",
    whatTone: "Welcher Ton?",
    formal: "Formell",
    casual: "Leger",
    persuasive: "Überzeugend",
    inspirational: "Inspirierend",
    humourous: "Humorvoll",
    explainContent: "Erläutern Sie kurz den Inhalt der Nachricht",
    emailSize: "E-Mail Größe",
    words: "Wörter",
    subject: "Betreff",
    textBody: "Textkörper",
    today: "Heute",
    title: "Titel",
    freePlan: "Kostenloser Plan",
    basicPlan: "Basisplan",
    getStartedDescription:
      "Beginnen Sie ohne Kosten mit der Erstellung von KI-gesteuerten E-Mails.",
    elevateEmailGameDescription:
      "Verbessern Sie Ihr E-Mail-Spiel mit erhöhter Flexibilität, perfekt für Fachleute und kleine Unternehmen.",
    monthlyMailsLimit: "Monatliches E-Mail-Limit",
    craftEmailsDescription:
      "Erstellen Sie bis zu 10 E-Mails pro Monat, ideal für den persönlichen Gebrauch oder für kleine Unternehmen.",
    basicWritingAssistance: "Grundlegende Schreibhilfe",
    writingAssistanceDescription:
      "Eine Vielzahl von Tönen und Stilen zur effektiven Kommunikation.",
    emailCustomization: "E-Mail-Anpassung",
    basicTemplatesDescription:
      "Grundlegende Vorlagen, um Ihren Nachrichten eine persönliche Note zu verleihen.",
    unlimitedEmailGeneration: "Unbegrenzte E-Mail-Erstellung",
    sendEmailsDescription:
      "Senden Sie so viele E-Mails wie nötig, um sicherzustellen, dass Ihre Kommunikation nie stoppt.",
    expandedStyleToneChoices: "Erweiterte Stil- und Tonwahl",
    styleToneSelectionDescription:
      "Eine größere Auswahl an Stilen und Tönen, um jedem Anlass und Publikum perfekt zu entsprechen.",
    advancedCustomizationOptions: "Erweiterte Anpassungsoptionen",
    enhancedToolsDescription:
      "Verbesserte Werkzeuge zur Personalisierung von E-Mails, um die Stimme Ihrer Marke unverkennbar zu machen.",
    whatIsRimbaudAI: "Was ist Rimbaud AI?",
    rimbaudDescription:
      "Rimbaud ist eine von KI angetriebene Software as a Service (SaaS), die die Erstellung von Inhalten für Einzelpersonen revolutioniert. Es vereinfacht die Erstellung von E-Mails und verschiedenen Inhaltstypen durch die Nutzung fortschrittlicher KI, um eine benutzerfreundliche Plattform zu bieten. Mit Rimbaud wählen Benutzer mühelos den Stil, Ton und die Länge des gewünschten Inhalts über eine einfache Schnittstelle aus, die es der KI ermöglicht, personalisierte und wirkungsvolle Nachrichten zu erstellen.",
    rimbaudCore:
      "Das Herzstück von Rimbaud ist sein intelligenter Motor, der darauf ausgelegt ist, Benutzervorlieben mit Präzision zu verstehen und umzusetzen, wodurch die Erstellung von Inhalten für alle zugänglich wird, unabhängig von technischen Fähigkeiten. Ob es darum geht, professionelle E-Mails, ansprechende Social-Media-Posts oder überzeugende Artikel zu erstellen, die KI von Rimbaud sorgt dafür, dass jedes Stück mit seinem beabsichtigten Publikum resoniert.",
    rimbaudAdvantages:
      "Rimbaud hebt sich ab, indem es die Komplexität, die mit der KI-gesteuerten Inhalterstellung verbunden ist, beseitigt und eine nahtlose Lösung für diejenigen bietet, die ihre Kommunikation verbessern möchten. Indem es die Privatsphäre sichert und ein nahtloses Benutzererlebnis priorisiert, ermächtigt Rimbaud Benutzer, KI für ihre kreativen Bedürfnisse zu nutzen, was die Erstellung von Inhalten nicht nur einfach, sondern auch angenehm macht.",
    rimbaudEssence:
      "Im Wesentlichen demokratisiert Rimbaud die KI-gestützte Inhalteerzeugung und bietet eine einzigartige Mischung aus Einfachheit, Personalisierung und Effizienz. Es ist mehr als ein Werkzeug; es ist eine Revolution in der Art und Weise, wie Einzelpersonen Inhalte erstellen, ermöglicht durch die Kraft der künstlichen Intelligenz.",
    signUp: "Registrieren",
    logIn: "Anmelden",
    logInWithGoogle: "Mit Google anmelden",
    dontHaveAccount: "Sie haben kein Konto?",
    alreadyHaveAccount: "Sie haben bereits ein Konto?",
    signUpWithGoogle: "Mit Google registrieren",
    copyToClipboard: "In die Zwischenablage kopieren",
    copied: "Kopiert!",
    addToArchive: "Zum Archiv hinzufügen",
    addedToArchive: "Zum Archiv hinzugefügt",
    yesterday: "Gestern",
    before: "Vorher",
    sendInGmail: "In Gmail senden",
    selectedPlan: "Ausgewählter Tarif",
    chooseThisPlan: "Diesen Tarif wählen",
    aboutUs: "Über uns",
    createFreeAccount: "Erstellen Sie Ihr kostenloses Rimbaut-Konto",
    transformEmailExperience:
      "Transformieren Sie Ihre E-Mail-Erfahrung mit KI-Intelligenz",
    welcomeBack: "Willkommen zurück",
    loginToContinueWriting: "Anmelden, um mit dem Schreiben fortzufahren",
    profile: "Profil",
    signOut: "Abmelden",
    rimbaudIntroduction: "Entdecken Sie Rimbaud, Ihren intelligenten kreativen Assistenten!",
    rimbaudRevolution: "Rimbaud revolutioniert die Content-Erstellung für alle, dank künstlicher Intelligenz. Erstellen Sie E-Mails und anderen Inhalt mühelos, ohne technisches Know-how.",
    howItWorks: "Wie funktioniert es?",
    chooseYourStyle: "Wählen Sie Ihren Stil:",
    chooseYourStyleDetail: "Wählen Sie den Ton und Stil Ihres Inhalts mit nur wenigen Klicks.",
    letAIHandleRest: "Lassen Sie die KI den Rest erledigen:",
    letAIHandleRestDetail: "Unsere intelligente Technologie erstellt personalisierte und wirkungsvolle Nachrichten basierend auf Ihren Vorlieben.",
    easyToUse: "Einfach zu bedienen:",
    easyToUseDetail: "Unsere benutzerfreundliche Oberfläche macht die Content-Erstellung für jedermann zugänglich, unabhängig von Ihrem technischen Kenntnisstand.",
    whyChooseRimbaud: "Warum Rimbaud wählen?",
    simplicity: "Einfachheit:",
    simplicityDetail: "Kein Kopfzerbrechen mehr, Rimbaud vereinfacht den gesamten Prozess.",
    personalization: "Personalisierung:",
    personalizationDetail: "Ihre Nachrichten werden einzigartig und auf Ihr Publikum zugeschnitten sein.",
    privacy: "Datenschutz:",
    privacyDetail: "Wir schützen Ihre persönlichen Daten und garantieren eine problemlose Benutzererfahrung.",
    joinRimbaudRevolution: "Schließen Sie sich noch heute der Rimbaud-Revolution an und entdecken Sie eine neue Art der Content-Erstellung, ermöglicht durch künstliche Intelligenz.",
  
    emailGenerationLimitReached:
      "Sie haben das E-Mail-Generierungslimit für Ihren aktuellen Plan erreicht.",
    remainingEmailsThisMonth: "Verbleibende E-Mails in diesem Monat:",
    premiumPlan: "Premium-Plan",
    availableOnPremiumPlan: "Verfügbar im Premium-Plan",
    modify: "Ändern",
    archiveConversations: "Unterhaltungen archivieren",
    oneClickGmailContent: "Inhalte von Gmail mit einem Klick senden",
    archiveConversationsDetail:
      "Archivieren Sie Ihre Unterhaltungen, um später darauf zurückzugreifen.",
    oneClickGmailContentDetail:
      "Senden Sie Inhalte von Gmail mit einem einzigen Klick direkt von unserer Plattform.",
    purchaseConfirmationTitle: "Kaufbestätigung",
    purchaseConfirmationMessage1: "Vielen Dank für Ihren Kauf!",
    purchaseConfirmationMessage2:
      "Ihre Transaktion wurde erfolgreich verarbeitet.",
    purchaseConfirmationMessage3:
      "Eine E-Mail-Bestätigung wurde an Ihre registrierte E-Mail-Adresse gesendet.",
    purchaseConfirmationMessage4:
      "Sie können jetzt alle Funktionen und Vorteile unseres Dienstes genießen.",
    purchaseConfirmationMessage5:
      "Wenn Sie Fragen haben oder weitere Unterstützung benötigen, zögern Sie bitte nicht, sich an unser Support-Team zu wenden.",
    purchaseConfirmationMessage6:
      "Fühlen Sie sich frei, unsere Dienste weiter zu durchsuchen oder besuchen Sie Ihr Dashboard, um die Details Ihres Kaufs zu sehen.",
    cancellationConfirmationTitle: "Bestätigung der Abo-Kündigung",
    cancellationConfirmationMessage1:
      "Ihre Abo-Kündigung wurde erfolgreich bearbeitet.",
    cancellationConfirmationMessage2: "Es tut uns leid, Sie gehen zu sehen!",
    cancellationConfirmationMessage3:
      "Wenn es etwas an unserem Service gab, das nicht Ihren Erwartungen entsprach, würden wir gerne Ihr Feedback hören.",
    cancellationConfirmationMessage4:
      "Ihr Input ist unschätzbar, da er uns hilft, uns zu verbessern und unsere Kunden in Zukunft besser zu bedienen.",
    cancellationConfirmationMessage5:
      "Wenn Sie Ihre Meinung ändern oder wenn wir Ihnen weiterhelfen können, zögern Sie bitte nicht, sich erneut zu melden.",
    cancellationConfirmationMessage6:
      "Denken Sie daran, dass Sie sich jederzeit erneut anmelden oder andere von uns angebotene Dienste erkunden können.",
  },
};
