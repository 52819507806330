import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#1D2127",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "50%",
  color: "white",
  overflowY: "auto",
};

const SimpleModal = ({ open, onClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default SimpleModal;
