import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import SmallTextInputNoBorder from "../components/misc/SmallTextInputNoBorder";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import SmallTextInputNoBorderLoading from "../components/misc/SmallTextInputNoBorderLoading";
import { LanguageContext } from "../context/LanguageContext";
import { usePremiumStatus } from "../hooks/usePremiumStatus";
import { usePortalUrl } from "../hooks/usePortalUrl";
import CircularProgress from "@mui/material/CircularProgress";
// import { useCheckoutUrl } from "../hooks/useCheckoutUrl";
import { useNavigate } from "react-router-dom";

const SettingsContent = () => {
  const { currentUser } = useAuth();
  const [profileUser, setProfileUser] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [, setSelectedLanguage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { translate, language } = useContext(LanguageContext);
  const [isPremium, subscriptionDetails, premiumError] = usePremiumStatus();
  const { url: portalUrl, loading, error } = usePortalUrl();
  // const priceId = "price_1P7XdrKxPduZsYMSK2h6rvZP";
  // const [checkoutUrl, checkoutError] = useCheckoutUrl(priceId);
  const navigate = useNavigate();

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
    updateLanguage(newLanguage);
  };

  const handlePricingRedirect = () => {
    navigate("/pricing");
  };

  console.log("isPremium: ", isPremium);

  const handlePortalRedirect = () => {
    if (portalUrl) {
      window.location.href = portalUrl;
    } else {
      alert("Erreur : Impossible de charger l'URL du portail.");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsFetching(true);
      try {
        const userRef = doc(db, "roles", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setProfileUser(userSnap.data());
          setIsFetching(false);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (currentUser?.uid) {
      fetchUserData();
    }
  }, [currentUser?.uid]);

  const updateLanguage = async (newLanguage) => {
    setIsSaving(true);
    try {
      const userDocRef = doc(db, "roles", currentUser.uid);
      await updateDoc(userDocRef, {
        language: newLanguage,
      });

      setProfileUser((prevProfile) => ({
        ...prevProfile,
        language: newLanguage,
      }));
    } catch (error) {
      console.error("Error updating language:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (premiumError) {
    return (
      <div className="generate-content">
        <div>Error: {premiumError?.message}</div>;
      </div>
    );
  }

  <div className="generate-content">
    {" "}
    <p>Error: {error?.message}</p>;
  </div>;

  const planContent = isPremium ? "Premium Plan" : profileUser?.role;

  return (
    <div className="generate-content">
      <div
        style={{
          width: "880px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div
          className="main-title-homepage"
          style={{
            fontSize: "40px",
            paddingLeft: "80px",
            paddingTop: "40px",
          }}
        >
          {translate("profile")}
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              paddingLeft: "80px",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignContent: "start",
              alignItems: "start",
            }}
          >
            <div
              className="label-button-group"
              style={{
                paddingLeft: "0px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ paddingRight: "40px" }}>
                <SmallTextInputNoBorder
                  label={translate("displayedName")}
                  content={currentUser?.displayName}
                  width="300px"
                />
              </div>
              <div style={{ paddingRight: "40px" }}>
                <SmallTextInputNoBorder
                  label={translate("email")}
                  content={currentUser?.email}
                  width="400px"
                />
              </div>
            </div>

            <div
              className="label-button-group"
              style={{
                paddingLeft: "0px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            ></div>
            <div
              className="label-button-group"
              style={{
                paddingLeft: "0px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ paddingBottom: "10px" }}>
                {translate("language")}
              </div>

              <div style={{ width: "200px" }}>
                <select
                  value={language}
                  style={{
                    marginRight: "5px",
                    backgroundColor: "#181b20",
                    color: "white",
                    border: "1px solid #767474",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    outline: "none",
                  }}
                  onChange={handleLanguageChange}
                >
                  <option value="en">English</option>
                  <option value="fr">Français</option>
                  <option value="es">Español</option>
                  <option value="de">Deutsch</option>
                </select>
              </div>

              {isSaving && (
                <div
                  style={{
                    color: "red",
                    width: "100px",
                    textAlign: "center",
                    paddingTop: "3px",
                    fontSize: "14px",
                  }}
                >
                  {translate("saving")}
                </div>
              )}
            </div>

            <div
              className="label-button-group"
              style={{
                paddingLeft: "0px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SmallTextInputNoBorderLoading
                  label={translate("plan")}
                  content={planContent}
                  isLoading={isFetching}
                />

                <div style={{ paddingTop: "10px" }}>
                  {subscriptionDetails?.currentPeriodStart && (
                    <div className="normalText">
                      Subscription Start:{" "}
                      <span className="normalTextWhite">
                        {subscriptionDetails.currentPeriodStart.toLocaleString()}
                      </span>
                    </div>
                  )}

                  {subscriptionDetails?.currentPeriodEnd && (
                    <div className="normalText">
                      Subscription End:{" "}
                      <span className="normalTextWhite">
                        {subscriptionDetails.currentPeriodEnd.toLocaleString()}
                      </span>
                    </div>
                  )}

                  {subscriptionDetails?.canceledAt ? (
                    <div className="normalText">
                      Canceled At:{" "}
                      <span className="normalTextWhite">
                        {subscriptionDetails.canceledAt.toLocaleString()}
                      </span>
                    </div>
                  ) : (
                    subscriptionDetails && (
                      <div className="normalText">Canceled At: N/A</div>
                    )
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                  paddingBottom: "9px",
                  paddingLeft: "40px",
                  marginTop: "35px",
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div
                    style={{
                      width: "200px",
                      height: "40px",
                      backgroundColor: "#ab73ee",
                      borderRadius: "10px",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    // onClick={() => {
                    //   if (url) window.location.href = url;
                    // }}
                    onClick={
                      isPremium ? handlePortalRedirect : handlePricingRedirect
                    }
                  >
                    {translate("changePlan")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
