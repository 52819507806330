import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "@firebase/app";

export function usePortalUrl() {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchPortalUrl() {
      setLoading(true);
      const app = getApp();
      const auth = getAuth(app);
      const user = auth.currentUser;

      if (!user) {
        setError(new Error("User not logged in"));
        setLoading(false);
        return;
      }

      try {
        const functions = getFunctions(app, "us-central1");
        const functionRef = httpsCallable(
          functions,
          "ext-firestore-stripe-payments-createPortalLink"
        );
        const { data } = await functionRef({
          customerId: user.uid,
          returnUrl: window.location.origin,
        });

        if (data.url) {
          setUrl(data.url);
          //   console.log("Reroute to Stripe portal: ", data.url);
        } else {
          throw new Error("No url returned");
        }
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchPortalUrl();
  }, []);

  return { url, loading, error };
}
