import React, { useContext, useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { showCustomToast } from "./CustomToast";
import { LanguageContext } from "../../context/LanguageContext";

const TextInputArea2Mobile = ({ label, content, typingSpeed = 10 }) => {
  const { translate } = useContext(LanguageContext);
  const [displayedContent, setDisplayedContent] = useState("");
  const wordCount = content.trim().split(/\s+/).length;
  const indexRef = useRef(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (indexRef.current < content.length) {
        const nextChar = content.charAt(indexRef.current);
        setDisplayedContent((prev) => prev + nextChar);
        // console.log(`Adding: ${nextChar}`); // Log the character being added
        indexRef.current += 1;
      } else {
        clearInterval(timer);
      }
    }, typingSpeed);

    return () => {
      clearInterval(timer);
      // console.log(`Final displayed content: '${displayedContent}'`); // Log the final content
    };
  }, [content, typingSpeed]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        console.log("Content copied to clipboard!");
        showCustomToast(translate("copied"), "success");
      })
      .catch((err) => {
        console.error("Failed to copy content: ", err);
      });
  };

  return (
    <div
      className="text-area-component-2"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="label-button-group-mobile">{label}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div className="input-text-area-result-mobile">{displayedContent}</div>
        <div
          title={translate("copyToClipboard")}
          style={{ paddingLeft: "10px", cursor: "pointer" }}
          onClick={handleCopy}
        >
          <ContentCopyIcon />
        </div>
      </div>

      <div
        style={{
          alignSelf: "flex-end",
          paddingRight: "35px",
          paddingTop: "5px",
          fontSize: "12px",
        }}
      >
        {wordCount} {translate("words")}
      </div>
    </div>
  );
};

export default TextInputArea2Mobile;
