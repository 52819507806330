import React from "react";
import NavbarMain from "./NavbarMain";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import NavbarMainMobile from "./NavbarMainMobile";
import CancelContentMobile from "./CancelContentMobile";
import CancelContent from "./CancelContent";

const Cancel = () => {
  const activeCategory = "learnmore";
  const activeCategoryMobile = "learnmore";
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div>
      {isMobile ? (
        <>
          {" "}
          <NavbarMainMobile activeCategoryMobile={activeCategoryMobile} />
          <CancelContentMobile />
        </>
      ) : (
        <>
          <NavbarMain activeCategory={activeCategory} />
          <CancelContent />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Cancel;
