import React, { useContext } from "react";
import StyledButtonEmpty from "../components/buttons/StyledButtonEmpty";
import StyledButton from "../components/buttons/StyledButton";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { useAuth } from "../context/AuthContext";

const HomepageContent = () => {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();
  const navigateTo = (path) => () => {
    navigate(path);
  };
  const { currentUser } = useAuth();

  return (
    <div className="homepage-content">
      {/* Le contenu de la page d'accueil va ici. */}

      <div
        className="main-title-homepage"
        style={{
          fontSize: "58px",
          // marginTop: "200px"
        }}
      >
        {translate("aiTool1")}
      </div>
      <div className="main-title-homepage" style={{ fontSize: "58px" }}>
        {translate("aiTool2")}
      </div>
      <div
        className="roboto-font"
        style={{ fontSize: "18px", paddingTop: "35px" }}
      >
        {translate("unleash")}
      </div>

      {/* Vous pouvez ajouter plus de contenu ici. */}

      <div
        style={{ display: "flex", flexDirection: "row", paddingTop: "80px" }}
      >
        <div style={{ paddingRight: "25px" }}>
          {!currentUser ? (
            <StyledButtonEmpty onClick={navigateTo("/signup")}>
              Get started
            </StyledButtonEmpty>
          ) : (
            ""
          )}
        </div>
        <StyledButton onClick={navigateTo("/learnmore")}>
          Learn more
        </StyledButton>
      </div>
    </div>
  );
};

export default HomepageContent;
