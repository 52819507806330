import React, { useContext, useState } from "react";
import "../css/Navbar.css";
import StyledButtonEmpty from "../components/buttons/StyledButtonEmpty";
import InputArea from "../components/inputs/InputArea";
import SimpleModal from "../components/modals/SimpleModal";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { showCustomToast } from "../components/misc/CustomToast";
import { CircularProgress } from "@mui/material";
import { LanguageContext } from "../context/LanguageContext";

const Footer = ({ activeCategory }) => {
  const { translate } = useContext(LanguageContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (email) {
      handleSubscription(email, setLoading, setEmail);
    } else {
      showCustomToast("Please enter a valid email address.", "error");
    }
  };

  const handleSubscription = async (email, setLoading, setEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      showCustomToast("Please enter a valid email address.", "error");
      return;
    }

    setLoading(true);

    const q = query(collection(db, "newsletter"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      showCustomToast(
        "This email is already subscribed to the newsletter.",
        "error"
      );
      setLoading(false);
      setEmail("");
      return;
    }

    try {
      await addDoc(collection(db, "newsletter"), {
        email: email,
        timestamp: new Date(),
      });
      showCustomToast("Email successfully registered!", "success");
      setEmail("");
    } catch (error) {
      console.error("Error adding document: ", error);
      showCustomToast("Failed to register email.", "error");
    }

    setLoading(false);
  };

  const handlePrivacyPolicyClick = () => {
    setModalOpen(true);
  };

  console.log("email:", email);

  const privacyPolicyContent = (
    <div style={{ fontFamily: "sans-serif" }}>
      <h2>{translate("privacyPolicyTitle")}</h2>

      <p>
        <strong>{translate("introductionTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("introductionContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("informationCollectionTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("informationCollectionContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("useOfInformationTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("useOfInformationContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("informationSharingTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("informationSharingContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("informationStorageTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("informationStorageContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("yourRightsTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("yourRightsContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("privacyPolicyChangesTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("privacyPolicyChangesContent")}
        </span>
        <br />
        <br />
      </p>

      <p>
        <strong>{translate("contactTitle")}</strong>
        <br />
        <br />
        <span style={{ fontSize: "14px", fontWeight: "300" }}>
          {translate("contactContent")}
        </span>
        <br />
        <br />
      </p>
    </div>
  );
  return (
    <div className="footer-container">
      <div className="footer-subcontainer">
        <div
          style={{
            display: "flex",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
            color: "white",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              height: "50%",
              paddingLeft: "100px",
              color: "white",
              fontSize: "18px",
            }}
          >
            {translate("joinNewsletter")}
          </div>
          <div
            className="roboto-font"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "flex-start",
              height: "50%",
              paddingLeft: "100px",
              color: "#a5a5a5",
              fontSize: "18px",
            }}
          >
            {translate("getUpdates")}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "right",
            alignContent: "center",
            alignItems: "center",
            color: "white",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              height: "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                paddingRight: "18px",
                paddingTop: "20px",
              }}
            >
              <InputArea value={email} onChange={setEmail} />
              {loading ? (
                <CircularProgress sx={{ color: "var(--purple-color)" }} />
              ) : (
                <StyledButtonEmpty onClick={handleClick}>
                  {translate("subscribe")}
                </StyledButtonEmpty>
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              height: "50%",
              fontSize: "13px",
              paddingRight: "60px",
            }}
          >
            {translate("privacyPolicyAgree1")}
            <span
              style={{
                cursor: "pointer",
                paddingLeft: "5px",
                color: "#ab73ee",
              }}
              onClick={handlePrivacyPolicyClick}
            >
              {translate("privacyPolicyAgree2")}
            </span>
          </div>
        </div>
      </div>
      <SimpleModal open={modalOpen} onClose={() => setModalOpen(false)}>
        <p>{privacyPolicyContent}</p>
      </SimpleModal>
    </div>
  );
};

export default Footer;
