import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../context/AuthContext";
import { LanguageContext } from "../context/LanguageContext";
import ProfileMenuMobile from "../components/misc/ProfileMenuMobile";
import LanguageIcon from "@mui/icons-material/Language";
import { showCustomToast } from "../components/misc/CustomToast";

export default function MenuMobile(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const [error, setError] = useState("");
  const { currentUser, logOut } = useAuth();
  const location = useLocation();
  const activeLink = location.state?.activeCategoryMobile;

  const navigateTo = (path) => () => {
    navigate(path);
  };

  const navigate = useNavigate();

  const handleMenuClose = () => {
    if (activeLink) {
      navigate(`/${activeLink}`);
    } else {
      navigate("/home");
    }
  };

  const { signInWithGoogle } = useAuth();

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  function handleSignInWithGoogle() {
    signInWithGoogle()
      .then((result) => {})
      .then(() => navigate("/"))
      .catch((error) => {});
  }

  async function handleLogout() {
    setError("");
    try {
      await logOut();
      showCustomToast(
        `User ${currentUser.email} successfuly logged out`,
        "success"
      );

      navigate("/login");
    } catch {
      setError("Failed to logout");
      console.log("Error", error);
    }
  }

  return (
    <div className="mobile-navbar-container">
      <div className="navbar-top-group">
        <div className="profile-widget-component-mobile">
          <ProfileMenuMobile
            // onClick={handleLogout}
            profilPic={currentUser?.photoURL}
            // notifications={notifications}
            // setNotifications={setNotifications}
          />
        </div>
        <div className="menu-items-container-mobile">
          <span
            className={`navbar-item-mobile ${
              activeLink === "home" ? "active" : ""
            }`}
            onClick={navigateTo("/")}
          >
            {translate("home")}
          </span>

          <span
            className={`navbar-item-mobile ${
              activeLink === "learnmore" ? "active" : ""
            }`}
            onClick={navigateTo("/learnmore")}
          >
            {translate("aboutUs")}
          </span>

          {currentUser ? (
            <span
              className={`navbar-item-mobile ${
                activeLink === "generate" ? "active" : ""
              }`}
              onClick={navigateTo("/generate")}
            >
              {translate("generate")}
            </span>
          ) : (
            ""
          )}
          {currentUser ? (
            <span
              className={`navbar-item-mobile ${
                activeLink === "library" ? "active" : ""
              }`}
              onClick={navigateTo("/library")}
            >
              {translate("library")}
            </span>
          ) : (
            ""
          )}

          <span
            className={`navbar-item-mobile ${
              activeLink === "pricing" ? "active" : ""
            }`}
            onClick={navigateTo("/pricing")}
          >
            {translate("pricing")}
          </span>

          {currentUser ? (
            <span
              className={`navbar-item-mobile ${
                activeLink === "Settings" ? "active" : ""
              }`}
              onClick={navigateTo("/settings")}
            >
              {translate("profile")}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="menu-items-container-mobile-bottom">
          <LanguageIcon
            style={{ marginRight: "10px", verticalAlign: "middle" }}
          />
          <select
            value={language}
            style={{
              marginRight: "5px",
              backgroundColor: "#181b20",
              color: "white",
              border: "1px solid #767474",
              borderRadius: "5px",
              padding: "5px 10px",
              outline: "none",
              fontSize: "18px",
            }}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
          </select>
        </div>
        <div className="menu-items-container-mobile">
          {currentUser ? (
            <>
              {" "}
              <span
                className={`navbar-item-mobile-2 ${
                  activeLink === "home" ? "active" : ""
                }`}
                onClick={handleLogout}
              >
                {translate("signOut")}
              </span>
              <span
                className={`navbar-item-mobile-3 ${
                  activeLink === "home" ? "active" : ""
                }`}
                onClick={handleMenuClose}
              >
                <CloseIcon style={{ fontSize: "30px" }} />
              </span>
            </>
          ) : (
            <>
              {" "}
              <span
                className={`navbar-item-mobile-2 ${
                  activeLink === "home" ? "active" : ""
                }`}
                onClick={navigateTo("/login")}
              >
                {translate("logIn")}
              </span>
              <span
                style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
              >
                {translate("dontHaveAccount")}
              </span>
              <span
                style={{
                  cursor: "pointer",
                  paddingLeft: "5px",
                  color: "#ab73ee",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "14px",
                  paddingTop: "15px",
                }}
                onClick={navigateTo("/signup")}
              >
                {translate("signUp")}
              </span>
              <span
                className={`navbar-item-mobile-3 ${
                  activeLink === "home" ? "active" : ""
                }`}
                onClick={handleMenuClose}
              >
                <CloseIcon style={{ fontSize: "30px" }} />
              </span>
            </>
          )}
        </div>
      </div>
      <div className="navbar-bottom-group">X</div>
    </div>
  );
}
