import React from "react";
import SignupContent from "./SignupContent";
import { useMediaQuery } from "react-responsive";
import SignupContentMobile from "./SignupContentMobile";

const Signup = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return <div>{isMobile ? <SignupContentMobile /> : <SignupContent />}</div>;
};

export default Signup;
