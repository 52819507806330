import React from "react";

const SmallTextInputNoBorderLoading = ({
  label,
  content,
  width,
  isLoading,
}) => {
  return (
    <div
      className="toggle-button-group-bis"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="label-button-group-2-mobile">{label}</div>
      <textarea
        className="input-text-area-small-no-border-mobile"
        style={{
          width,
          color: isLoading ? "red" : "inherit",
          fontWeight: isLoading ? "300" : "500",
          fontSize: isLoading ? "18px" : "18px",
          textAlign: "center",
          paddingTop: "7px",
        }}
        value={isLoading ? "Loading..." : content}
        maxLength={40}
        disabled={isLoading} // Désactive l'input pendant le chargement
      />
    </div>
  );
};

export default SmallTextInputNoBorderLoading;
