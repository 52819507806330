import React from "react";
import "../App.css";
import "react-toastify/dist/ReactToastify.css";
import LoginContent from "./LoginContent";
import { useMediaQuery } from "react-responsive";
import LoginContentMobile from "./LoginContentMobile";

function Login() {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return <div>{isMobile ? <LoginContentMobile /> : <LoginContent />}</div>;
}

export default Login;
