import React, { useContext } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { showCustomToast } from "./CustomToast";
import { LanguageContext } from "../../context/LanguageContext";

const TitleResultMobile = ({ label, content }) => {
  const { translate } = useContext(LanguageContext);
  const handleCopy = () => {
    // Utilisation de l'API navigator.clipboard pour copier le texte
    navigator.clipboard
      .writeText(content)
      .then(() => {
        // Vous pourriez afficher une notification ou un message indiquant que le texte a été copié avec succès
        console.log("Copied to clipboard!");
        showCustomToast(translate("copied"), "success");
      })
      .catch((err) => {
        // Gérer les erreurs éventuelles
        console.error("Failed to copy content: ", err);
      });
  };
  return (
    <div
      className="text-area-component-4"
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <div className="label-button-group-mobile">{label}</div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div className="input-text-title-result-mobile">{content}</div>
        <div
          style={{ paddingLeft: "10px", cursor: "pointer" }}
          onClick={handleCopy}
          title={translate("copyToClipboard")}
        >
          <ContentCopyIcon />
        </div>
      </div>
    </div>
  );
};

export default TitleResultMobile;
