import React from "react";

const StyledButtonEmpty = ({ onClick, children }) => {
  return (
    <button className="styledButtonEmpty" onClick={onClick}>
      {children}
    </button>
  );
};

export default StyledButtonEmpty;
