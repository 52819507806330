import React from "react";
import "../css/Navbar.css";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

const NavbarMainMobile = ({ activeCategoryMobile }) => {
  const navigate = useNavigate();
  console.log("activeCategoryMobile:", activeCategoryMobile);

  const handleMenuOpen = () => {
    navigate("/menumobile", { state: { activeCategoryMobile } });
  };

  return (
    <div className="navbar-container">
      <div className="navbar-left-mobile">
        <span className="app-name">Rimbaud</span>
      </div>
      <div className="navbar-right-mobile">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          className="menu-button"
        >
          <MenuIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default NavbarMainMobile;
