import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { getApp } from "@firebase/app";

export function usePremiumStatus() {
  const [isPremium, setIsPremium] = useState(false);
  const [error, setError] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  useEffect(() => {
    const app = getApp();
    const auth = getAuth(app);
    const userId = auth.currentUser?.uid;

    if (!userId) {
      setError(new Error("User not logged in"));
      return;
    }

    const db = getFirestore(app);
    const subscriptionsRef = collection(
      db,
      "customers",
      userId,
      "subscriptions"
    );
    const q = query(
      subscriptionsRef,
      where("status", "in", ["trialing", "active"])
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (snapshot.docs.length === 0) {
          console.log("No active or trialing subscriptions found");
          setIsPremium(false);
        } else {
          console.log("Active or trialing subscription found");
          setIsPremium(true);
          const data = snapshot.docs[0].data();
          setSubscriptionDetails({
            created: data.created.toDate(), // Convert timestamp to Date object
            currentPeriodStart: data.current_period_start.toDate(),
            currentPeriodEnd: data.current_period_end.toDate(),
            canceledAt: data.canceled_at ? data.canceled_at.toDate() : null, // Check if exists and convert
            cancelAt: data.cancel_at ? data.cancel_at.toDate() : null,
            endedAt: data.ended_at ? data.ended_at.toDate() : null,
          });
        }
      },
      (error) => {
        setError(error);
      }
    );

    return () => unsubscribe();
  }, []);

  //   return [isPremium, error];
  return [isPremium, subscriptionDetails, error];
}
