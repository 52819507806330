import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const CancelContentMobile = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="pricing-content-mobile">
      <div
        className="main-title-homepage"
        style={{
          fontSize: "24px",
          marginBottom: "20px",
          width: "80%",
          textAlign: "center",
          paddingTop: "40px",
        }}
      >
        {translate("cancellationConfirmationTitle")}
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "0px",
          paddingTop: "10px",
          textAlign: "justify",
        }}
      >
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage1")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage2")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage3")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage4")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage5")}
        </div>
        <div
          className="roboto-font"
          style={{
            paddingBottom: "15px",
            width: "100%",
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          {translate("cancellationConfirmationMessage6")}
        </div>
      </div>
    </div>
  );
};

export default CancelContentMobile;
