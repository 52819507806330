import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getApp } from "@firebase/app";

export function useCheckoutUrl(priceId) {
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const app = getApp();
    const auth = getAuth(app);
    const db = getFirestore(app);
    let unsubscribe; // Définissez 'unsubscribe' ici pour qu'il soit accessible dans la portée globale de useEffect

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const checkoutSessionRef = collection(
          db,
          "customers",
          user.uid,
          "checkout_sessions"
        );
        const createSession = async () => {
          try {
            const docRef = await addDoc(checkoutSessionRef, {
              price: priceId,
              success_url: window.location.origin,
              cancel_url: window.location.origin,
            });

            unsubscribe = onSnapshot(docRef, (snap) => {
              const data = snap.data();
              if (data?.error) {
                unsubscribe();
                setError(new Error(`An error occurred: ${data.error.message}`));
                setUrl(null);
              } else if (data?.url) {
                unsubscribe();
                setUrl(data.url);
                setError(null);
              }
            });
          } catch (error) {
            setError(error);
            setUrl(null);
          }
        };

        createSession();
      } else {
        setError(new Error("User is not authenticated"));
        setUrl(null);
      }
    });

    // Cleanup function to remove listeners if the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [priceId]);

  return [url, error];
}
