import React, { useContext, useEffect, useState } from "react";
import LineComponent from "../components/misc/LineComponent";
import { LanguageContext } from "../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import Lottie from "react-lottie";
import LottieLoader from "../components/misc/sasZb5q9fs.json";
import { format, isToday, isYesterday, subDays } from "date-fns";

const LibraryContent = () => {
  const { translate } = useContext(LanguageContext);
  const [archives, setArchives] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noArchivesFound, setNoArchivesFound] = useState(false);
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Dans LibraryContent

  const handleEditClick = (archiveData) => {
    navigate("/generate", { state: { archiveData } });
  };

  useEffect(() => {
    const fetchUserArchives = async () => {
      setIsLoading(true);
      if (auth.currentUser) {
        try {
          const userArchiveRef = doc(db, "archives", auth.currentUser.uid);
          const docSnap = await getDoc(userArchiveRef);

          if (docSnap.exists()) {
            // Transforme les données Firestore en tableau d'archives
            const archivesData = Object.entries(docSnap.data()).map(
              ([key, value]) => ({
                id: key,
                ...value,
                timestamp: value.timestamp.toDate(),
              })
            );
            setArchives(archivesData);
          } else {
            setNoArchivesFound(true);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des archives:", error);
          setNoArchivesFound(true);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUserArchives();
  }, []);

  if (archives.length === 0) {
    return (
      <div className="generate-content">
        <div style={{ paddingLeft: "80px", paddingTop: "20px" }}>
          <h2>{translate("No archived content")}</h2>
        </div>
      </div>
    );
  }

  const classifyArchives = () => {
    const yesterday = subDays(new Date(), 1);

    const sortedArchives = archives.sort((a, b) => b.timestamp - a.timestamp);

    const todayArchives = sortedArchives.filter((archive) =>
      isToday(archive.timestamp)
    );
    const yesterdayArchives = sortedArchives.filter((archive) =>
      isYesterday(archive.timestamp)
    );
    const otherArchives = sortedArchives.filter(
      (archive) => archive.timestamp < yesterday
    );

    return { todayArchives, yesterdayArchives, otherArchives };
  };

  const { todayArchives, yesterdayArchives, otherArchives } =
    classifyArchives();

  if (isLoading) {
    return (
      <div className="library-content">
        {" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
            height: "80%",
          }}
        >
          <Lottie options={defaultOptions} height={60} width={200} />
        </div>
      </div>
    );
  }

  if (noArchivesFound) {
    return <p>{translate("No archives found")}</p>;
  }

  return (
    <div className="generate-content">
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div
          className="main-title-homepage"
          style={{
            fontSize: "40px",
            paddingLeft: "0px",
            paddingTop: "0px",
            // marginTop: "200px"
          }}
        >
          {translate("library")}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              paddingLeft: "0px",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "start",
              alignItems: "start",
            }}
          >
            {todayArchives.length > 0 && (
              <>
                <h3 style={{ color: "white" }}>
                  {translate("today")} - {format(new Date(), "PPP")}
                </h3>
                {todayArchives.map((archive, index) => (
                  <LineComponent
                    key={index}
                    content={archive.title}
                    dateArchive={format(archive.timestamp, "PPP")}
                    onClick={handleEditClick}
                    archiveData={archive}
                  />
                ))}
              </>
            )}
            {yesterdayArchives.length > 0 && (
              <>
                <h3 style={{ color: "white" }}>
                  {translate("yesterday")} -{" "}
                  {format(subDays(new Date(), 1), "PPP")}
                </h3>
                {yesterdayArchives.map((archive, index) => (
                  <LineComponent
                    key={index}
                    content={archive.title}
                    dateArchive={format(archive.timestamp, "PPP")}
                    onClick={handleEditClick}
                    archiveData={archive}
                  />
                ))}
              </>
            )}
            {otherArchives.length > 0 && (
              <>
                <h3 style={{ color: "white" }}>{translate("before")}</h3>
                {otherArchives.map((archive, index) => (
                  <LineComponent
                    key={index}
                    content={archive.title}
                    dateArchive={format(archive.timestamp, "PPP")}
                    onClick={handleEditClick}
                    archiveData={archive}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryContent;
