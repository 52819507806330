import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./pages/ProtectedRoute";
import Homepage from "./pages/Homepage";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Generate from "./pages/Generate";
import Library from "./pages/Library";
import Pricing from "./pages/Pricing";
import Settings from "./pages/Settings";
import LearnMore from "./pages/LearnMore";
import { LanguageProvider } from "./context/LanguageProvider";
import MenuMobile from "./pages/MenuMobile";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import CookieConsent from "./components/misc/CookieConsent";

function App() { 

  

  return (
    <Router>
      <LanguageProvider>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <AuthProvider>
          <CookieConsent />
          <Routes>
            <Route
              path="/home"
              element={
                //<ProtectedRoute>
                <Homepage />
                //</ProtectedRoute>
              }
            />
            <Route path="/" element={<Homepage />} />
            <Route path="/homepage" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/generate"
              element={
                <ProtectedRoute>
                  <Generate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/library"
              element={
                <ProtectedRoute>
                  <Library />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pricing"
              element={
                // <ProtectedRoute>
                <Pricing />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/success"
              element={
                <ProtectedRoute>
                  <Success />
                </ProtectedRoute>
              }
            />

            <Route
              path="/cancel"
              element={
                <ProtectedRoute>
                  <Cancel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/learnmore"
              element={
                // <ProtectedRoute>
                <LearnMore />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/menumobile"
              element={
                // <ProtectedRoute>
                <MenuMobile />
                // </ProtectedRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
