import React from "react";
import HomepageContent from "../pages/HomepageContent"; // Assurez-vous de créer ce composant
import NavbarMain from "../pages/NavbarMain";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import HomepageContentMobile from "./HomepageContentMobile";
import NavbarMainMobile from "./NavbarMainMobile";

const Homepage = () => {
  const activeCategory = "Home";
  const activeCategoryMobile = "home";

  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div>
      {isMobile ? (
        <div style={{ width: "100%", height: "100%" }}>
          <NavbarMainMobile activeCategoryMobile={activeCategoryMobile} />
          <HomepageContentMobile />
          {/* <FooterMobile /> */}
        </div>
      ) : (
        <>
          <NavbarMain activeCategory={activeCategory} />
          <HomepageContent />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Homepage;
